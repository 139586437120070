import { useSelector, useDispatch } from 'react-redux'
import { closePopup, openLoginBox } from '../../actions/modalAction'

import ModalView from './View'
import PopUp from '../Popup/Popup'

export default function Popup() {
  const dispatch = useDispatch()
  const { active } = useSelector((state) => state.modal.popup)

  return (
    <ModalView
      active={active}
      setActive={() => dispatch(closePopup())}
      centered={false}
      number='3vw'
    >
      <PopUp
        onClick={() => dispatch(closePopup())}
        onRegisterClicked={() => dispatch(openLoginBox(false))}
      />
    </ModalView>
  )
}