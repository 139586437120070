export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const UPDATE_CART_PRODUCTS = "UPDATE_CART_PRODUCTS";
export const FETCH_CART_PRODUCTS = "FETCH_CART_PRODUCTS";
export const FETCH_CART_PRODUCTS_LOCALLY = "FETCH_CART_PRODUCTS_LOCALLY";

export const ERROR_CART = "ERROR_CART";
export const CLEAR_CART = "CLEAR_CART";

export const cartReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_TO_CART:
      let newState = [];

      const iInCart = state.findIndex((product) => {
        return product.product.id == action.payload.product.id
      });

      if (iInCart >= 0) {
        newState = state.map((item, index) => {
          if (index == iInCart) {
            return action.payload
          }

          return item
        })
      } else {
        newState = [...state, { ...action.payload }]
      }

      localStorage.setItem("cartItems", JSON.stringify(newState));

      return newState;
    case DELETE_FROM_CART:
      let newState2 = state.filter((item) => item?.product?.id !== action.payload);
      localStorage.setItem("cartItems", JSON.stringify(newState2));

      return newState2;
    case FETCH_CART_PRODUCTS:
      return action?.payload || [];
    case FETCH_CART_PRODUCTS_LOCALLY:
      let newState3 = JSON.parse(localStorage.getItem("favorites")) || [];
      return newState3;
    case UPDATE_CART_PRODUCTS:
      return state;
    case CLEAR_CART:
      localStorage.removeItem("cartItems");
      return [];
    default:
      return state;
  }
};
