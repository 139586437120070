import {
  OPEN_LOGIN_BOX,
  CLOSE_LOGIN_BOX,
  SWITCH_LOGIN_BOX,
  OPEN_POPUP,
  CLOSE_POPUP,
  OPEN_SERVICE_BUY,
  CLOSE_SERVICE_BUY,
  OPEN_SUCCESS_POSTED,
  CLOSE_SUCCESS_POSTED,
  OPEN_CALLBACK,
  CLOSE_CALLBACK,
  OPEN_CHANGE_PASSWORD,
  CLOSE_CHANGE_PASSWORD
} from "../reducers/modalReducer";

export const openLoginBox = (isLogin) => (dispatch) => {
  dispatch({ type: OPEN_LOGIN_BOX, payload: isLogin })
}

export const closeLoginBox = () => (dispatch) => {
  dispatch({ type: CLOSE_LOGIN_BOX, payload: null })
}

export const switchLoginBox = () => (dispatch) => {
  dispatch({ type: SWITCH_LOGIN_BOX })
}

export const openPopup = () => (dispatch) => {
  dispatch({ type: OPEN_POPUP })
}

export const closePopup = () => (dispatch) => {
  dispatch({ type: CLOSE_POPUP })
}

export const openServiceBuy = (serviceId) => (dispatch) => {
  dispatch({ type: OPEN_SERVICE_BUY, payload: { serviceId } })
}

export const closeServiceBuy = () => (dispatch) => {
  dispatch({ type: CLOSE_SERVICE_BUY })
}

export const openSuccessPosted = () => (dispatch) => {
  dispatch({ type: OPEN_SUCCESS_POSTED })
}

export const closeSuccessPosted = () => (dispatch) => {
  dispatch({ type: CLOSE_SUCCESS_POSTED })
}

export const openCallback = () => (dispatch) => {
  dispatch({ type: OPEN_CALLBACK })
}

export const closeCallback = () => (dispatch) => {
  dispatch({ type: CLOSE_CALLBACK })
}

export const openChangePassword = () => (dispatch) => {
  dispatch({ type: OPEN_CHANGE_PASSWORD })
}

export const closeChangePassword = () => (dispatch) => {
  dispatch({ type: CLOSE_CHANGE_PASSWORD })
}