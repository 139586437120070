import * as api from "../api";
import { FETCH_SERVICES, ORDER_SERVICE } from "../reducers/servicesReducer";

export const fetchServices = () => async (dispatch) => {
  try {
    const data = await api.fetchServices();
    if (data.success)
      dispatch({ type: FETCH_SERVICES, payload: data.data });
  } catch (error) {
    dispatch({ type: FETCH_SERVICES });
  }
}

export const orderService = (serviceId) => async (dispatch) => {
  try {
    const data = await api.orderService(serviceId);
    if (data.success)
      dispatch({ type: ORDER_SERVICE, payload: data.data });
  } catch (error) {
    dispatch({ type: ORDER_SERVICE });
  }
}