import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeedCard from "../../components/Cards/FeedCard";
import Pagination from "../../components/Pagination";
import feed from "../../assets/icons/feed.svg";

import styles from "../sale/sale.module.css";

export default function FavoritesPage() {
  const favorites = useSelector((state) => state.favorite);
  const [paginatedFavorites, setPaginatedFavorites] = useState([]);
  const [page, setPage] = useState(1);

  const perPageItems = 12;
  const pageCount = Math.ceil(favorites?.length / perPageItems);

  const paginateFavs = () => {
    let offset = (page - 1) * perPageItems;
    let newArr = favorites?.slice(offset).slice(0, perPageItems);
    setPaginatedFavorites(newArr);
  };

  useEffect(() => {
    paginateFavs();
  }, [page, favorites]);

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Избранное</span>
        </div>
        <h1 className="head1">Избранное</h1>

        <div className="flex-wrap">
          {paginatedFavorites.map((product) => {
            return (
              <FeedCard
                id={product.id}
                key={product.id}
                title={product.title}
                itemType={product.itemType}
                img={product.img}
                text={product.text}
                price={product.price}
                // bannerText="Новинки"
                // bannerColor="blue"
              />
            );
          })}
        </div>

        {favorites.length > 0 && (
          <div className={styles.pagination}>
            <Pagination length={pageCount} onClick={(i) => setPage(i)} />
            <div className="flex">
              <span className={styles.spanText}>Всего товаров:</span>
              <strong className={styles.number}>{favorites.length}</strong>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
