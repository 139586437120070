import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { postRequestOnService } from "../../api"
import { closeServiceBuy, openSuccessPosted } from '../../actions/modalAction'

import ModalView from './View'

import { IoClose } from "react-icons/io5"
import InputMask from "react-input-mask"

import styles from "./login.module.css"

export default function ServiceBuyModal() {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({})
  const [errorMsg, setErrorMsg] = useState([])

  const { active, serviceId } = useSelector((state) => state.modal.serviceBuy)

  const handleChange = (e) => {
    e.preventDefault()
    let name = e.target.name
    let value = e.target.value

    setFormData((prev) => {
      return { ...prev, [name]: value }
    })
  }

  const onSubmitForm = (e) => {
    e.preventDefault()
    setErrorMsg([])

    let newFormData = {
      ...formData,
      phone: "+" + formData?.phone?.replace(/[^\d]/g, ""),
    }

    postRequestOnService(newFormData, serviceId)
      .then(() => {
        dispatch(closeServiceBuy())
        dispatch(openSuccessPosted())
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  const setErrorMessage = (error) => {
    const data = error?.response?.data?.data
    const message = error?.response?.data?.message
    const messages = []

    if (!data && message) {
      messages.push(message)
    } else if (data) {
      Object.entries(data).forEach(([key, errors]) => {
        errors.forEach((message) => {
          messages.push(message)
        })
      })
    }

    setErrorMsg(messages)
  }

  const renderErrors = (messages) => {
    if (!messages && !messages.length) {
      return
    }

    return (
      <React.Fragment>
        {
          messages.map((message) => {
            return <div className={styles.errorMsg}>{message}</div>
          })
        }
      </React.Fragment>
    )
  }

  const renderForm = () => {
    return (
      <div className={styles.paper}>
        <div className={styles.header}>
          Заказать услугу
          <i onClick={() => dispatch(closeServiceBuy())}>
            <IoClose />
          </i>
        </div>
        <div className={styles.body}>
          <div className={styles.textBox}>
            <p className={styles.label}>Имя</p>
            <input
              name="name"
              placeholder="Имя"
              type="text"
              onChange={handleChange}
              className={styles.textField}
            />
          </div>
          <div className={styles.textBox}>
            <p className={styles.label}>Контакты</p>
            <InputMask
              name="phone"
              placeholder="+7 (707) 746 47 94"
              mask="+7 (999) 999-99-99"
              maskChar="_"
              onChange={handleChange}
              className={styles.textField}
            />
          </div>
          <div className={styles.textBox}>
            <p className={styles.label}>Электронная почта</p>
            <input
              name="email"
              placeholder="m.kasanov@gmail.com"
              type="text"
              onChange={handleChange}
              className={styles.textField}
            />
          </div>
          {renderErrors(errorMsg)}
          <div className={styles.btnBox}>
            <button
              className={"green-btn" + " " + styles.fullWidth}
              onClick={(e) => {
                onSubmitForm(e)
              }}
            >
              Заказать услугу
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ModalView
      active={active}
      setActive={() => dispatch(closeServiceBuy())}
      hideBg={true}
    >
      {renderForm()}
    </ModalView>
  )


}