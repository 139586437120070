import React from "react";
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import { RiMenu2Fill } from "react-icons/ri";

import styles from "./tabbar.module.css";

export default function Tabbar({
  categories,
  catalog,
  setCatalog,
  searchItem,
  setSearchItem,
  onBack,
}) {
  const setSelectedCategories = (subcategory, parentId) => {
    if (catalog.find((prev) => prev.name === subcategory.name)) {
      setCatalog((ex) => {
        let newState = [];
        ex.every((item) => {
          if (
            item.name === subcategory.name ||
            (item.parentId === parentId && item.name !== subcategory.name)
          )
            return false;

          newState.push(item);
          return true;
        });

        return newState;
      });
    } else
      setCatalog((ex) => {
        let newState = [];
        ex.every((item) => {
          if (item.parentId === parentId && item.name !== subcategory.name)
            return false;

          newState.push(item);
          return true;
        });

        return [...newState, { ...subcategory, parentId }];
      });
  };

  const onClick = (subcategory, parentId) => {
    setSelectedCategories(subcategory, parentId);
    if (!(subcategory.children?.length > 0))
      setSearchItem({
        id: subcategory.id,
        name: subcategory.name,
      });
  };

  const generateNestedCategory = (subcategories) => {
    return subcategories.children?.length > 0 ? (
      <div
        className={
          catalog.find((prev) => prev.name === subcategories.name)
            ? styles.dropdownContent + " " + styles.active
            : styles.dropdownContent
        }
      >
        {subcategories.children.map((subcategory, i) => {
          let lastDepthClassName =
            searchItem.name === subcategory.name
              ? styles.subCatalog + " " + styles.active
              : styles.subCatalog;

          let preLastsDepth = catalog.find(
            (prev) => prev.name === subcategory.name
          )
            ? styles.rowTab + " " + styles.active
            : styles.rowTab;

          return (
            <div key={i}>
              <div
                id={subcategory.name}
                className={
                  subcategory.children?.length > 0
                    ? preLastsDepth
                    : lastDepthClassName
                }
                onClick={(e) => {
                  e.preventDefault();
                  onClick(subcategory, subcategories.id);
                }}
              >
                <span>{subcategory.name}</span>
                {subcategory.children?.length > 0 && (
                  <i>
                    <IoIosArrowDown />
                  </i>
                )}
              </div>
              {subcategory.children?.length > 0 &&
                generateNestedCategory(subcategory)}
            </div>
          );
        })}
      </div>
    ) : (
      <div className={styles.dropdownContent + " " + styles.onlyChild}>
        <div
          className={
            searchItem.name === subcategories.name
              ? styles.subCatalog + " " + styles.active
              : styles.subCatalog
          }
          onClick={() => onClick(subcategories, -1)}
        >
          <span>{subcategories.name}</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.mobile} onClick={onBack}>
        <i>
          <IoIosArrowBack />
        </i>
        <strong>Вернуться в каталог</strong>
      </div>
      <div className={styles.row}>
        <i>
          <RiMenu2Fill />
        </i>
        <strong>КАТЕГОРИЯ</strong>
      </div>

      {categories.map((category, i) => (
        <div key={i}>
          {category?.children?.length > 0 ? (
            <div>
              <div
                className={
                  catalog.find((prev) => prev.name === category.name)
                    ? styles.rowTab + " " + styles.active
                    : styles.rowTab
                }
                onClick={() => {
                  if (catalog.find((prev) => prev.name === category.name))
                    setCatalog([]);
                  else
                    setCatalog([
                      { id: category.id, name: category.name, parentId: -1 },
                    ]);
                }}
              >
                <span>{category.name}</span>
                <i>
                  <IoIosArrowDown />
                </i>
              </div>
              {category.children && generateNestedCategory(category)}
            </div>
          ) : (
            generateNestedCategory(category)
          )}
        </div>
      ))}
    </div>
  );
}
