import { NavLink } from "react-router-dom";
import Carousel from "../Carousel";
import FeedCard from "../Cards/FeedCard";
import { useHistory } from "react-router";

import styles from "../../pages/home/home.module.css";
import useWindowSize from "../../hooks/useWindowSize";
import MobileCarousel from "../Carousel/mobileCarousel";

export default function CarouselTopArrows({
  headerText,
  linkText,
  link,
  img,
  moveLeft = false,
  arrayItems = [],
}) {
  const history = useHistory();
  const width = useWindowSize();

  return (
    <div>
      <div className={styles.headerBox}>
        <h2 className={styles.headerText}>{headerText}</h2>
        <NavLink to={link} className={"blueUnderline " + styles.underline}>
          {linkText}
        </NavLink>
      </div>
      <div className="negative-contain">
        {width > 700 ? (
          <Carousel slidesToShow={4} arrows infinite={false}>
            {arrayItems.map((item) => {
              return (
                  <FeedCard
                    img={item.thumbnail}
                    variations={item.variations}
                    id={item.id}
                    title={item.name}
                    itemType={item.category}
                    text={item.description}
                    // prevPrice={i % 2 === 0 ? "850" : undefined}
                    price={item.price}
                    bannerText="Новинка"
                    bannerColor="blue"
                    moveLeft={moveLeft}
                  />
              );
            })}
          </Carousel>
        ) : (
          <MobileCarousel slidesToShow={2}>
            {arrayItems.map((item) => {
              return (
                  <FeedCard
                    img={img}
                    variations={item.variations}
                    id={item.id}
                    title={item.name}
                    itemType={item.category}
                    text={item.description}
                    // prevPrice={i % 2 === 0 ? "850" : undefined}
                    price={item.price}
                    bannerText="Новинка"
                    bannerColor="blue"
                    moveLeft={moveLeft}
                  />
              );
            })}
          </MobileCarousel>
        )}
      </div>
      <div className="mobile-button">
        <button
          className="green-btn"
          onClick={() => {
            history.push(link);
          }}
        >
          {linkText}
        </button>
      </div>
    </div>
  );
}
