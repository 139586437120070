import styles from "./s.module.css";
import feed from "../../../../assets/icons/feed.svg";
import { IoMdArrowDropdown } from "react-icons/io";
import { BiArrowBack } from "react-icons/bi";

import React, { useEffect, useState } from "react";
import OrderDetails from "./OrderDetails";
import Image from "../../../../utils/Image";

const stateArr = [
  "В обработке",
  "На сборке",
  "На этапе доставки",
  "В пути",
  "Доставлено",
];

export default function OrdersPage({ orders, statuses }) {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [orderState, setOrderState] = useState();

  // if (loading) return <div>Loading...</div>;

  const renderOrders = () => {
    if (details) {
      return <OrderDetails data={details} />
    }

    let newOrders = orders

    if (orderState) {
      newOrders = orders.filter((order) => {
        return order.status === orderState.status
      })
    }

    return newOrders.map((order) => {
      let price = 0

      order?.order_items?.forEach((item) => {
        price += item?.price * item?.unit_quantity
      })

      return (
        <div className={styles.card}>
          <div className={styles.cardText}>
            <h3>Заказ №{order.id}</h3>
            <span className={styles.orangeText}>{order.status}</span>
            <p className={styles.payment}>К оплате: {price} ₸</p>
            <span className={styles.delivery}>Доставка ожидается 27 июня</span>
            <button className="green-btn" onClick={() => setDetails(order)}>
              Подробнее о заказе
            </button>
          </div>
          <div className={styles.flexWrap}>
            {order?.order_items.map((item, i) => {
              return (
                <Image key={i} src={item?.product?.thumbnail} className={styles.feedCard} />
              )
            })}
          </div>
        </div>
      )
    })
  }

  return (
    <div>
      {details ? (
        <div
          className={styles.back}
          onClick={() => {
            setDetails(null)
          }}
        >
          <i>
            <BiArrowBack />
          </i>
          <span>Назад</span>
        </div>
      ) : (
        <div className={styles.flex}>
          <h2>История заказов</h2>
          <div
            className={styles.filter}
            onClick={() => setOpenDropdown((e) => !e)}
          >
            <span>
              {orderState ? orderState.display_name : "Статус заказа"}
            </span>
            <i>
              <IoMdArrowDropdown />
            </i>
            <div
              className={
                openDropdown
                  ? styles.dropdownContent + " " + styles.active
                  : styles.dropdownContent
              }
            >
              {statuses.map((status, i) => (
                <div
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    setOrderState(status);
                  }}
                >
                  {status.display_name}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {renderOrders()}
    </div>
  );
}
