import React, { useEffect, useState } from "react";

import FeedCard from "../../components/Cards/FeedCard";
import Pagination from "../../components/Pagination";

import styles from "./sale.module.css";
import { fetchItemsByCategory } from "../../api";

export default function SalesPage() {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    searchByPage(page);
  }, [page]);

  const searchByPage = (currentPage) => {
    fetchItemsByCategory({
      params: {
        discounted: 1,
        page: currentPage,
      },
    }).then((val) => {
      if (val.success) setProducts(val.data.products);
    });
  };

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Уценка</span>
        </div>
        <h1 className="head1">Уценка</h1>

        <div className="flex-wrap">
          {products.map((product, i) => {
            return (
              <FeedCard
                id={product.id}
                key={product.id}
                itemType="type"
                img={product.thumbnail}
                text={product.description}
                prevPrice={i % 2 === 0 ? "850" : undefined}
                price={product.price}
                bannerText={i % 2 === 0 ? "Акция" : undefined}
                bannerColor="orange"
              />
            );
          })}
        </div>

        {totalPage > 0 && (
          <div className={styles.pagination}>
            <Pagination length={totalPage} onClick={(i) => setPage(i)} />
            <div className="flex">
              <span className={styles.spanText}>Всего товаров с уценкой:</span>
              <strong className={styles.number}>{totalItems}</strong>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
