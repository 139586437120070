import { useState } from "react";
import { BsArrowRight } from "react-icons/bs";

import styles from "./pagination.module.css";

export default function Pagination({ length, onClick }) {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className={styles.pagination}>
      {Array.from({ length: length }, (_, i) => (
        <div
          key={i}
          className={
            i + 1 === currentPage
              ? styles.pageBox + " " + styles.active
              : styles.pageBox
          }
          onClick={() => {
            if (currentPage === i + 1) return;
            setCurrentPage(i + 1);
            if (onClick) onClick(i + 1);
          }}
        >
          {i + 1}
        </div>
      ))}
      {currentPage < length && <div className={styles.pageBox}>...</div>}

      <i
        onClick={() => {
          if (currentPage === length) return;

          setCurrentPage((i) => {
            if (onClick) onClick(i + 1);
            return i + 1;
          });
        }}
      >
        <BsArrowRight />
      </i>
    </div>
  );
}
