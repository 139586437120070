import { useSelector, useDispatch } from 'react-redux'
import { closeChangePassword } from '../../actions/modalAction'

import ModalView from './View'
import ChangePassword from "../../pages/account/sections/PersonalData/sections/ChangePassword";

export default function ChangePasswordModal() {
  const dispatch = useDispatch()
  const { active } = useSelector((state) => state.modal.changePassword)

  return (
    <ModalView
      active={active}
      setActive={() => dispatch(closeChangePassword())}
      hideBg
    >
      <ChangePassword restorePassword={active} setOpenModal={() => dispatch(closeChangePassword())} />
    </ModalView>
  )
}