import { useEffect, useState } from "react";
import { FiBookmark } from "react-icons/fi";
import { MdTouchApp } from "react-icons/md";
import { RiMenu2Fill } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import Pagination from "../../components/Pagination";
import FeedCard from "../../components/Cards/FeedCard";
import { useLocation } from "react-router";
import Tabbar from "./sections/tabbar";
import { fetchItemsByCategory } from "../../api";

import styles from "./catalog.module.css";

export default function CatalogPage({ categories }) {
  const [catalog, setCatalog] = useState([]);
  const [openCategory, setOpenCategory] = useState(false);
  const [searchItem, setSearchItem] = useState({ id: 1, name: "" });
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [novelty, setNovelty] = useState(0);
  const [hit, setHit] = useState(0);
  const [discounted, setDiscounted] = useState(0);
  const { search } = useLocation();

  useEffect(() => {
    const params = search.substring(1).split("&");

    params.forEach((query) => {
      let queryParams = query.split("=");
      switch (queryParams[0]) {
        case "hit":
          if (hit === 0) setHit(queryParams[1]);
          break;
        case "novelty":
          if (novelty === 0) setNovelty(queryParams[1]);
          break;
        case "category_id":
          let nestedCategoryIds = queryParams[1].split(",");
          let newCatalog = [];
          let currentCategory = categories.find(
            (eachCategory) =>
              eachCategory.id === Number.parseInt(nestedCategoryIds[0])
          );
          if (!currentCategory) break;
          newCatalog.push(currentCategory);
          setSearchItem({});

          for (let i = 1; i < nestedCategoryIds.length; i++) {
            let category = currentCategory.children.find(
              (eachCategory) =>
                eachCategory.id === Number.parseInt(nestedCategoryIds[i])
            );

            if (
              i + 1 === nestedCategoryIds.length &&
              (!category?.children || category?.children.length === 0)
            ) {
              setSearchItem({
                id: category.id,
                name: category.name,
              });
            }

            if (!category) break;
            newCatalog.push(category);
            currentCategory = category;
          }

          setCatalog(newCatalog);
          break;

        case "search_query":
          setSearchQuery(queryParams[1]);
          break;
        default:
          break;
      }
    });
  }, [search]);

  useEffect(() => {
    if (searchItem?.id)
      fetchItemsByCategory({
        params: { novelty, hit, discounted, page, category_id: searchItem.id },
      }).then((val) => {
        if (val.success) {
          setItems(val.data.products);
          setPage(val.data.current_page);
          setTotalPages(val.data.total);
        }
      });
  }, [searchItem]);

  const searchByPage = (currentPage) => {
    fetchItemsByCategory({
      params: {
        novelty,
        hit,
        discounted,
        page: currentPage,
        category_id: searchItem.id,
      },
    }).then((val) => {
      if (val.success) {
        setItems(val.data.products);
        setPage(val.data.current_page);
        setTotalPages(val.data.total);
      }
    });
  };

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Каталог</span>
          {!searchQuery &&
            catalog.map((selectedCategory) => (
              <span key={selectedCategory.name}>{selectedCategory.name}</span>
            ))}
        </div>
        <h1 className="head1">
          {searchQuery
            ? searchQuery
            : catalog.length > 0
            ? catalog[catalog.length - 1].name
            : "Каталог"}
        </h1>

        <div className={"flex " + styles.margin}>
          <div className={styles.desktopFilterTab}>
            <Tabbar
              categories={categories}
              catalog={catalog}
              setCatalog={setCatalog}
              searchItem={searchItem}
              setSearchItem={setSearchItem}
            />
          </div>

          <div className={styles.contentBody}>
            <div className={styles.flexTabs}>
              <div
                className={styles.row + " " + styles.mobileFilter}
                onClick={() => setOpenCategory((e) => !e)}
              >
                <i>
                  <RiMenu2Fill />
                </i>
                <strong>КАТАЛОГ ПРОДУКЦИИ</strong>
                <span className={styles.arrow}>
                  <IoIosArrowForward />
                </span>
              </div>

              <div
                className={
                  openCategory
                    ? styles.mobileFilterTab + " " + styles.active
                    : styles.mobileFilterTab
                }
              >
                <Tabbar
                  categories={categories}
                  catalog={catalog}
                  setCatalog={setCatalog}
                  searchItem={searchItem}
                  setSearchItem={setSearchItem}
                  onBack={() => setOpenCategory(false)}
                />
              </div>
            </div>
            <div className={styles.filterButtons}>
              <div
                className={styles.row}
                onClick={() =>
                  setNovelty((ex) => {
                    if (ex === 0) return 1;
                    else return 0;
                  })
                }
              >
                <i>
                  <FiBookmark />
                </i>
                <span>Новое поступление</span>
              </div>
              <div
                className={styles.row}
                onClick={() =>
                  setHit((ex) => {
                    if (ex === 0) return 1;
                    else return 0;
                  })
                }
              >
                <i>
                  <MdTouchApp />
                </i>
                <span>Хиты продаж</span>
              </div>
            </div>

            <div className="flex-wrap">
              {items.map((item, i) => (
                <FeedCard
                  key={i}
                  title={item.name}
                  id={item.id}
                  itemType={item.category}
                  img={item?.thumbnail}
                  text={item?.description}
                  prevPrice={i % 2 === 0 ? "850" : undefined}
                  price={item?.price}
                  bannerText={i % 2 === 0 ? "Акция" : undefined}
                  bannerColor="orange"
                  favorite={i % 2 === 1}
                  wrapNumber={3}
                />
              ))}
            </div>

            {totalPages > 0 && (
              <div className={styles.flexPagination}>
                <Pagination
                  length={totalPages}
                  onClick={(page) => {
                    searchByPage(page);
                  }}
                />
                <div className="flex">
                  <span className={styles.spanText}>
                    Всего товаров с уценкой:
                  </span>
                  <strong className={styles.number}>58</strong>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
