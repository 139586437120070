/// catalog
import cat from "../../assets/images/home/cat.jpg";
import dog from "../../assets/images/home/dog.jpg";
import bird from "../../assets/images/home/bird.jpg";
import fish from "../../assets/images/home/fish.jpg";
import reptiles from "../../assets/images/home/reptiles.jpg";
import rodent from "../../assets/images/home/rodent.jpg";
import farm from "../../assets/images/home/farm.jpg";
import pigeons from "../../assets/images/home/pigeons.jpg";

import latest from "../../assets/icons/home/latest.svg";
import promotion from "../../assets/icons/home/promotion.svg";
import hot from "../../assets/icons/home/hot.svg";
import services from "../../assets/icons/home/services.svg";

/// advantages
import zoos from "../../assets/icons/home/zoos.svg";
import watehouse from "../../assets/icons/home/watehouse.svg";
import feed from "../../assets/icons/home/feed.svg";
import partner from "../../assets/icons/home/partner.svg";
import bigCatalog from "../../assets/icons/home/bigCatalog.svg";
import support from "../../assets/icons/home/support.svg";
import delivery from "../../assets/icons/home/delivery.svg";

export const ANIMAL_CATALOG = [
  // { name: "Кошки", img: cat, animal: true },
  // { name: "Собаки", img: dog, animal: true },
  // { name: "Птицы", img: bird, animal: true },
  // { name: "Рыбы", img: fish, animal: true },
  // { name: "Рептилии", img: reptiles, animal: true },
  // { name: "Грызуны", img: rodent, animal: true },
  // { name: "Сельхоз животные", img: farm, animal: true },
  // { name: "Голуби", img: pigeons, animal: true },
  // { name: "Новинки", img: latest, animal: false, id: "/catalog?novelty=1" },
  // {
  //   name: "Aкции, скидки, розыгрыши",
  //   img: promotion,
  //   animal: false,
  //   id: "/catalog?discount=1",
  // },
  // { name: "Xиты продаж", img: hot, animal: false, id: "/catalog?hit=1" },
  // {
  //   name: "Наши услуги",
  //   img: services,
  //   animal: false,
  //   id: "/services",
  // },
];

export const ANIMAL_TEXT = [
  "Аксессуары",
  " Амуниция",
  " Витамины",
  " Влажный корм",
];

export const ADVANTAGES = [
  { name: "Сеть зоомагазинов в крупных городах Казахстана", img: zoos },
  { name: "Собственные оптовые склады", img: watehouse },
  { name: "Производства кормов, аксесуаров, и аквариумов", img: feed },
  { name: "Сотрудничаем с ведущими компаниями мира", img: partner },
  { name: "Огромный выбор живого товара", img: bigCatalog },
  {
    name: "Консультация ведущих специалистов. Крепкая сплоченная команда",
    img: support,
  },
  { name: "Доставка по всему Казахстану в кратчайшие сроки", img: delivery },
];

export const SERVICE_TEXT = [
  "Доставка, установка аквариума.",
  // "2 полный комплекс обслуживания аквариума",
  // "3 разовая чистка аквариума",
];

export const TOP_NAVBAR = [
  { name: "О Компании", to: "/about" },
  { name: "Доставка", to: "/delivery" },
  { name: "Контакты", to: "/contact" },
];

export const PHONES = ["+7 (903) 154-70-11", "+7 (903) 154-70-11"];

export const LEFT_NAVBAR = [
  { name: "Акции", to: "/promotions" },
  { name: "Услуги", to: "/services" },
  { name: "Скидки", to: "/discounts" },
  { name: "Новинки", to: "/novelty" },
];

export const RIGHT_NAVBAR = [
  { name: "Уценка", to: "/sale" },
  { name: "Наши работы", to: "/works" },
  { name: "Справочники", to: "/references" },
  { name: "Новости", to: "/news" },
];

export const PROFILE_TABS = [
  "Мои данные",
  "История заказов",
  "Мои отзывы",
  "Памятка клиента",
  "Бонусы",
  "Уведомления",
  // "Данные питомца",
];
