import moment from "moment";
import "moment/locale/ru";

import styles from "./s.module.css";
import feed from "../../../../assets/icons/feed.svg";

const text = "Понравился ли вам товар? Пожалуйста оставьте отзыв!";

export default function Notifications({ notifications, read }) {
  moment.locale('ru')

  const renderStatuses = () => {
    return notifications.map((notification) => {
      return (
        <div className={styles.card}>
          <p className={styles.date}>{moment(notification.read_at).fromNow()}</p>
          <p className={styles.strong}>{notification.headline}</p>
          <p>{notification.description}</p>
          {!notification.read && <button className="green-btn" onClick={() => {read(notification.id)}}>Прочитано</button>}
        </div>
      );
    })
  };

  const postReviewAboutProduct = () => {
    return (
      <div className={styles.card}>
        <p className={styles.date}>20 июля 2021</p>
        <p className={styles.strong}>{text}</p>
        <p className={styles.strong}>Заказ №1223</p>
        <div className={styles.flexWrap}>
          {Array.from({ length: 4 }, (_, i) => (
            <img key={i} src={feed} alt="feed" className={styles.feedCard} />
          ))}
        </div>
        <button className="green-btn">Оставить отзыв</button>
      </div>
    );
  };

  return (
    <div>
      <h2 className={styles.head2}>Уведомления</h2>
      {renderStatuses()}
      {/*{postReviewAboutProduct()}*/}
    </div>
  );
}
