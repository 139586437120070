import * as api from "../api";
import {
  GET_USER_DATA,
  USER_DATA_LOADING,
  USER_DATA_ERROR,
  LOGOUT,
} from "../reducers/authReducer";

export const getUserData = (config) => async (dispatch) => {
  try {
    dispatch({ type: USER_DATA_LOADING });
    const json = await api.getUser(config);
    if (json.success) dispatch({ type: GET_USER_DATA, payload: { data: json.data, config } });
    else dispatch({ type: USER_DATA_ERROR, payload: json.message });
  } catch (error) {
    console.log(error);
    dispatch({ type: USER_DATA_ERROR, payload: "Something went wrong" });
  }
};

export const logoutAction = () => async (dispatch) => {
  try {
    dispatch({ type: USER_DATA_LOADING });
    api.deleteUserTokens().finally(() => dispatch({ type: LOGOUT }));
  } catch (error) {
    console.log(error);
  }
};
