import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RiPhoneLine } from "react-icons/ri";
import { openServiceBuy } from '../../actions/modalAction'
import ServiceCard from "../../components/Cards/ServiceCard";
import { openCallback, openLoginBox } from '../../actions/modalAction'
import styles from "./services.module.css";
import Image from "../../utils/Image";

export default function ServicesPage() {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);
  const authData = useSelector((state) => state.auth);
  const [currentService, setCurrentService] = useState(0);

  const lowerBlock = services.length ? services[0].lower_block : '';
  const upperBlock = services.length ? services[0].upper_block : '';

  const firstServices = () => {
    return services?.filter((service) => {
      return service.type === 'first'
    })
  }

  const secondServices = () => {
    return services?.filter((service) => {
      return service.type === 'second'
    })
  }

  const thirdServices = () => {
    return services?.filter((service) => {
      return service.type === 'third'
    })
  }

  const renderFirstServices = () => {
    return firstServices().map((service, i) => {
      if (i === 0) {
        return (
          <div className={styles.column1}>
            <h2 className="head1">Лучшее наше предложение</h2>
            <p>{service.description}</p>
            <div className="flex-center margin-top-2">
              <RiPhoneLine className="green-icon font-size-2" />
              <div className={styles.phone}>
                <strong>Телефон</strong>
                <span>7 777 654 3210</span>
              </div>
            </div>

            <button className="green-btn margin-top-2" onClick={() => dispatch(openCallback())}>
              Обратная связь
            </button>
          </div>
        )
      }

      if (i === 1) {
        return (
          <div className={styles.column2}>
            <ServiceCard purple text={service.name} pointText={service.description} price={service.price} />
          </div>
        )
      }

      if (i === 2) {
        return (
          <div className={styles.column3}>
            <ServiceCard purple text={service.name} pointText={service.description} price={service.price} />
          </div>
        )
      }

      return null
    })
  }

  const renderSecondServices = () => {
    const activeService = secondServices()[currentService]

    if (!activeService) {
      return null
    }

    return (
      <div className={styles.mainServices}>
        <div className={styles.leftPart}>
          {secondServices()?.map((service, i) => (
            <strong
              key={i}
              className={
                currentService === i
                  ? styles.text + " " + styles.active
                  : styles.text
              }
              onClick={() => setCurrentService(i)}
            >
              {service.name}
            </strong>
          ))}

          <div className={styles.desktopPart}>
            <div className={styles.line}></div>
            <small className="small">{lowerBlock}</small>
          </div>
        </div>

        <div className={styles.imgBox}>
          <div className={styles.textOnImg}>
            <h2>{activeService.name}</h2>
            <p>{activeService.description}</p>

            <div className={styles.buttons}>
              <button
                className="green-btn"
                onClick={() => dispatch(openServiceBuy(activeService.id))}
              >
                Заказать услугу
              </button>
              {renderServiceOrderButton()}
            </div>
          </div>
          <Image src={activeService.thumbnail} />
        </div>

        <div className={styles.mobilePart}>
          <div className={styles.line}></div>
          <small className="small">{lowerBlock}</small>
        </div>
      </div>
    )
    return secondServices()?.map((item) => {
      return (
        <div className={styles.serviceBox}>
          <h3>{item.name}</h3>
          <span>{item.description}</span>
          <button
            className="green-btn"
            onClick={() => dispatch(openServiceBuy(item.id))}
          >Заказать услугу</button>
        </div>
      )
    })
  }

  const renderThirdServices = () => {
    return thirdServices()?.map((item, i) => {
      return (
        <div className={styles.serviceBox}>
          <h3>{item.name}</h3>
          <span>{item.description}</span>
          <button
            className="green-btn"
            onClick={() => dispatch(openServiceBuy(item.id))}
          >Заказать услугу</button>
        </div>
      )
    })
  }

  function renderServiceOrderButton() {
    if (!authData?.data) {
      return (
        <a
          className={styles.textBtn}
          onClick={() => dispatch(openLoginBox(true))}
        >
          Хочу скидку на услуги
        </a>
      )
    }

    return null
  }

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Наши услуги</span>
        </div>

        <h2 className="head1">Наши услуги</h2>
        <p className={styles.bottomText}>{upperBlock}</p>
        {renderSecondServices()}
        <div className="margin-top-8">
          <h2 className="head1">Ещё услуги</h2>
          {renderThirdServices()}
        </div>

        <div className={styles.bestOffers}>
          {renderFirstServices()}
        </div>
      </div>
    </div>
  );
}
