import styles from "./s.module.css";
import img from "../../assets/images/Intersect.png";

import { IoMdClose } from "react-icons/io";

const popup_text =
  "При регистрации на сайте вы получите 5% скидку на первый заказ!";

export default function Popup({ onClick, onRegisterClicked }) {
  return (
    <div className={styles.flex}>
      <div className={styles.textDiv}>
        <strong>{popup_text}</strong>
        <button
          className="green-btn"
          onClick={() => {
            onClick();
            onRegisterClicked();
          }}
        >
          Зарегистрироваться
        </button>
      </div>
      <div className={styles.imgBox}>
        <img src={img} alt="animal" />
      </div>

      <i className={styles.icon} onClick={onClick}>
        <IoMdClose />
      </i>
    </div>
  );
}
