import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'

import { openLoginBox, openSuccessPosted } from '../../actions/modalAction'

import CarouselTopArrows from "../../components/CarouselTopArrows/CarouselTopArrows";
import { IoRadioButtonOn, IoRadioButtonOff } from "react-icons/io5";
import { RiArrowDownSLine } from "react-icons/ri";

import feed from "../../assets/icons/feed.svg";
import visa from "../../assets/icons/payment/visa.svg";
import mastercard from "../../assets/icons/payment/mastercard.svg";
import mir from "../../assets/icons/payment/mir.svg";
import paypal from "../../assets/icons/payment/paypal.svg";

import ItemInCart from "../../components/Cards/ItemInCart";
import { useSelector } from "react-redux";
import {
	orderAsGuest,
	orderAsUser,
	fetchItemsByCategory,
	fetchCities,
	fetchPaymentTypes,
	fetchUserBonuses,
	applyBonusesOrPromo,
  getDeliveryPage
} from "../../api";

import styles from "./cart.module.css";

const text =
  "Сухой корм для взрослых собак средних пород крупных пород с ягненком и рисом";

export default function CartPage() {
  const cart = useSelector((state) => state.cart);
  const authData = useSelector((state) => state.auth);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [deliveryState, setDeliveryState] = useState(1);
  const [deliveryType, setDeliveryType] = useState([]);
  const [packageState, setPackageState] = useState(0);
  const [contactFormState, setContactFormState] = useState({});
  const [addressFormState, setAddressFormState] = useState({});
  const [hideForm, setHideForm] = useState(true);
  const [countries, setCountries] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [bonuses, setBonuses] = useState(0);
  const [needBonuses, setNeedBonuses] = useState(0);
  const [promo, setPromo] = useState('');
  const [errors, setErrors] = useState([]);
  const [promoErrors, setPromoErrors] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const dispatch = useDispatch()

  const [openCountriesDropdown, setOpenCountriesDropdown] = useState(false);
  const [openCitiesDropdown, setOpenCitiesDropdown] = useState(false);
  let price = 0

  cart.forEach((item) => {
    price += item?.product?.price * item?.unit_quantity
  })

  useEffect(() => {
    fetchItemsByCategory({
      params: { novelty: 1 },
    }).then((json) => {
      if (json.success) setSimilarProducts(json.data.products);
    });

    fetchCities().then((val) => {
      if (val.success) {
        setCountries(val.data);
      }
    });

    fetchPaymentTypes().then((val) => {
      if (val.success) {
        setPaymentTypes(val.data);
      }
    })

    fetchUserBonuses(authData.config).then((val) => {
      if (val.bonuses) {
        setBonuses(val.bonuses)
      }
    })

		getDeliveryPage().then((response) => {
			setDeliveryType(response.data);

      const firstDeliveryType = response.data.length ? response.data[0] : null;

      if (firstDeliveryType) {
				setDeliveryState(firstDeliveryType.id);
				setDeliveryPrice(firstDeliveryType.price);
			}
    })

    if (authData?.data) {
      setHideForm(false)
    }
  }, [ authData ]);

  const handleChange = (e, index) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;

    if (index === 0)
      setContactFormState((prev) => {
        return { ...prev, [name]: value };
      });
    else if (index === 1)
      setAddressFormState((prev) => {
        return { ...prev, [name]: value };
      });
  };

  const showOrderForm = () => {
    setHideForm((e) => !e);
  };

  const submitFormUnauthorizedOrder = (e) => {
    e.preventDefault()

    dropErrorMessage()

    const order_items = cart.map((cartProduct) => {
      return {
        id: cartProduct?.product?.id,
        unit_quantity:  cartProduct?.unit_quantity
      }
    });

    const formData = {
      order_items,
      ...contactFormState,
      ...addressFormState,
      delivery_type_id: deliveryState,
      payment_type_id: 84,
    };


    orderAsGuest(formData)
      .then((response) => {
        dispatch(openSuccessPosted())
      }).catch((error) => {
      setErrorMessage(error, setErrors)
    })
  };

  const submitFormAuthorizedOrder = (e) => {
    e.preventDefault()

    dropErrorMessage()

    const { name, email, phone, surname } = authData.data
    const formData = {
      ...contactFormState,
      ...addressFormState,
      delivery_type_id: deliveryState,
      payment_type_id: 84,
      apartment_number: parseInt(addressFormState.apartment_number),
      house_number: parseInt(addressFormState.house_number),
      name,
      email,
      phone,
      surname
    }

    orderAsUser(formData, authData.config)
      .then((response) => {
        dispatch(openSuccessPosted())
      })
      .catch((error) => {
        console.log(error?.response)
        setErrorMessage(error, setErrors)
      })
  };

  const dropErrorMessage = () => {
    setErrors([])
    setPromoErrors([])
  }

  const setErrorMessage = (error, setErr) => {
    const { data, message } = error?.response?.data
    const messages = []

    if (!data && message) {
      messages.push(message)
    } else {
      Object.entries(data).forEach(([key, errors]) => {
        errors.forEach((message) => {
          messages.push(message)
        })
      })
    }

    setErr(messages)
  }

  const renderCountries = () => {
    return (
      <div
        className={
          styles.textField + " " + styles.width3 + " " + styles.dropdown
        }
        onClick={() => setOpenCountriesDropdown((e) => !e)}
      >
        {renderCountry()}
        <i>
          <RiArrowDownSLine />
        </i>
        <div
          className={
            openCountriesDropdown
              ? styles.dropdownContent + " " + styles.active
              : styles.dropdownContent
          }
        >
          {countries.map((country, i) => {
            return (
              <div
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  setAddressFormState((prev) => {
                    return { ...prev, country_id: country.id, city_id: null };
                  });
                }}
              >
                {country.name}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderCountry = () => {
    const country = getCountry();

    return country?.name || "Выберите страну*";
  }

  const renderCities = () => {
    const cities = getCities()

    return (
      <div
        className={
          styles.textField + " " + styles.width3 + " " + styles.dropdown
        }
        onClick={() => setOpenCitiesDropdown((e) => !e)}
      >
        {getCity()}
        <i>
          <RiArrowDownSLine />
        </i>
        <div
          className={
            openCitiesDropdown
              ? styles.dropdownContent + " " + styles.active
              : styles.dropdownContent
          }
        >
          {cities.map((city, i) => (
            <div
              key={i}
              onClick={(e) => {
                e.preventDefault();
                setAddressFormState((prev) => {
                  return { ...prev, city_id: city.id };
                });
              }}
            >
              {city.name}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const getCity = () => {
    const cities = getCities()
    const cityId = addressFormState?.city_id

    if (cityId) {
      const city = cities.find((c) => {
        return c.id == cityId
      })

      return city?.name
    }

    return "Выберите город*"
  }

  const getCountry = () => {
    const countryId = addressFormState?.country_id

    if (countryId) {
      return countries.find((c) => {
        return c.id == countryId
      })
    }

    return []
  }

  const getCities = () => {
    return getCountry()?.cities || []
  }

  const applyPromo = () => {
    if (notAuthorized()) {
      return null
    }

    let data = {}

    if (needBonuses) {
      data.bonuses = needBonuses
    }
    if (promo.length) {
      data.promocode = promo
    }

    applyBonusesOrPromo(data, authData.config)
      .then((response) => {
        setDiscount(response.total)
      })
      .catch((error) => {
        setErrorMessage(error, setPromoErrors)
      })
  }

  const notAuthorized = () => {
    return !authData || !authData?.data
  }

  const onSetBonuses = (e) => {
    const value = e.target.value

    if (notAuthorized() || (value > bonuses)) {
      return null
    }

    setNeedBonuses(e.target.value)
  }

  const onSetPromo = (e) => {
    if (notAuthorized()) {
      return null
    }

    setPromo(e.target.value)
  }

  const renderErrors = (messages) => {
    if (!messages && !messages.length) {
      return
    }

    return (
      <React.Fragment>
        {
          messages.map((message) => {
            return <div className={styles.errorMsg}>{message}</div>
          })
        }
      </React.Fragment>
    )
  }

  const renderBonusBox = () => {
    if (!authData.data || !cart.length) {
      return null
    }

    return (
      <div className={styles.bonusBox}>
        <div className="contain">
          <div className={styles.bonusInsideBox}>
            <div className={styles.bonusAmount}>
              <span className={styles.bonusPrice}>{bonuses} ₸</span>
              <span>Накопленных бонусов</span>
            </div>
            <div className={styles.buttonsBox}>
              <input
                type="number"
                placeholder="Потратить бонусы"
                value={needBonuses || ''}
                onChange={onSetBonuses}
                disabled={notAuthorized()}
              />
              <input
                placeholder="Введите промокод"
                onChange={onSetPromo}
                disabled={notAuthorized()}
              />
              <button
                className={"green-btn " + styles.greenBtn}
                onClick={applyPromo}
              >
                Применить
              </button>
            </div>
          </div>
          {renderErrors(promoErrors)}
        </div>
      </div>
    )
  }

  const renderPriceBox = () => {
    if (!cart.length) {
      return null
    }

    return (
      <div className={styles.checkoutGrid}>
        <div>
          <div className={styles.rowPrice + " " + styles.orange}>
            <strong>Итого:</strong>
            <span className={styles.price}>{price}</span>
            <small>₸</small>
          </div>
        </div>
        <div>
          <div className={styles.rowPrice}>
            <strong>ПРОМОКОД</strong>
            <span className={styles.price}>{discountSum}</span>
            <small>₸</small>
          </div>
        </div>
        <div>
          <div className={styles.rowPrice + " " + styles.orange}>
            <strong></strong>
            <span className={styles.price}>{priceWithDiscount}</span>
            <small>₸</small>
          </div>
        </div>
        <div>
          <div className={styles.rowPrice}>
            <strong>ДОСТАВКА</strong>
            <span className={styles.price}>{deliveryPrice}</span>
            <small>₸</small>
          </div>
        </div>
        <div>
          <div className={styles.rowPrice + " " + styles.orange}>
            <strong>Итого к оплате:</strong>
            <span className={styles.price}>{priceWithDiscountAndDelivery}</span>
            <small>₸</small>
          </div>
        </div>
      </div>
    )
  }

  const renderOrderButtons = () => {
    if (!cart.length || (authData?.data != null)) {
      return null
    }

    return (
      <div className={styles.buttons}>
        <button className="green-btn" onClick={showOrderForm}>
          Оформить заказ без регистрации
        </button>
        <button className={styles.button2} onClick={() => dispatch(openLoginBox(true))}>
          Зайти в аккаунт и оформить заказ
        </button>
      </div>
    )
  }

  const renderDeliveryType = () => {
    return (
			<div className="flex margin-top-4">
        {deliveryType?.map((type) => {
          return (
						<div
							className={styles.radioButton}
							onClick={() => {
								setDeliveryState(type.id);
								setDeliveryPrice(type.price);
							}}
						>
							<i>
								{deliveryState === type.id ? (
									<IoRadioButtonOn />
								) : (
									<IoRadioButtonOff />
								)}
							</i>
							<span>{type.headline}</span>
						</div>
          )
        })}
			</div>
    )
  }

  const renderForm = () => {
    if (hideForm || !cart.length) {
      return null
    }

    return (
      <form>
        {!authData?.data && (
          <div>
            <h2 className={styles.head2}>ВАШИ КОНТАКТЫ:</h2>
            <div className={"flex-wrap " + styles.marginLeft}>
              <input
                type="text"
                placeholder="Имя*"
                name="name"
                onChange={(e) => handleChange(e, 0)}
                className={styles.textField + " " + styles.width2}
              />
              <input
                type="text"
                placeholder="Фамилия*"
                name="surname"
                onChange={(e) => handleChange(e, 0)}
                className={styles.textField + " " + styles.width2}
              />
              <input
                type="text"
                placeholder="Телефон*"
                name="phone"
                onChange={(e) => handleChange(e, 0)}
                className={styles.textField + " " + styles.width2}
              />
              <input
                type="text"
                placeholder="Электронный адрес*"
                name="email"
                onChange={(e) => handleChange(e, 0)}
                className={styles.textField + " " + styles.width2}
              />
            </div>
          </div>
        )}
        {renderDeliveryType()}
        <h2 className={styles.head2}>АДРЕС ДОСТАВКИ:</h2>
        <div className={"flex-wrap " + styles.marginLeft}>
          <input
            type="text"
            placeholder="Улица*"
            name="street"
            onChange={(e) => handleChange(e, 1)}
            className={styles.textField + " " + styles.width3}
          />
          <input
            type="number"
            placeholder="Дом*"
            name="house_number"
            onChange={(e) => handleChange(e, 1)}
            className={styles.textField + " " + styles.width3}
          />
          <input
            type="number"
            placeholder="Квартира*"
            name="apartment_number"
            onChange={(e) => handleChange(e, 1)}
            className={styles.textField + " " + styles.width3}
          />
          {renderCountries()}
          {renderCities()}
          <input
            type="text"
            placeholder="Почтовый индекс*"
            name="zip_code"
            onChange={(e) => handleChange(e, 1)}
            className={styles.textField + " " + styles.width3}
          />
        </div>
        <input
          type="text"
          className={styles.textField + " " + styles.width1}
          name="comment"
          onChange={(e) => handleChange(e, 1)}
          placeholder="Дополнительная информация*"
        />

        <div className={styles.flexible}>
          <div
            className={styles.radioButton}
            onClick={() => {
              if (packageState !== 0) setPackageState(0);
            }}
          >
            <i>
              {packageState === 0 ? (
                <IoRadioButtonOn />
              ) : (
                <IoRadioButtonOff />
              )}
            </i>
            <span>Добавить упаковку</span>
          </div>

          <div
            className={styles.radioButton}
            onClick={() => {
              if (packageState !== 1) setPackageState(1);
            }}
          >
            <i>
              {packageState === 1 ? (
                <IoRadioButtonOn />
              ) : (
                <IoRadioButtonOff />
              )}
            </i>
            <span>Отменить</span>
          </div>
        </div>

        <div className={styles.paymentBox}>
          <button
            className="green-btn"
            onClick={(e) => {
              if (authData?.data == null) submitFormUnauthorizedOrder(e);
              else submitFormAuthorizedOrder(e);
            }}
          >
            Купить
          </button>

          <div className={styles.paymentIcons}>
            <i>
              <img src={visa} alt="visa" />
            </i>
            <i>
              <img src={mastercard} alt="mastercard" />
            </i>
            <i>
              <img src={mir} alt="mir" />
            </i>
            <i>
              <img src={paypal} alt="paypal" />
            </i>
          </div>
        </div>
        {renderErrors(errors)}
      </form>
    )
  }

  const discountSum = Math.abs(discount)
  const priceWithDiscount = price ? price - Math.abs(discount) : 0
  const priceWithDiscountAndDelivery = priceWithDiscount + deliveryPrice

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Корзина</span>
        </div>
        <div className={styles.flexSpace}>
          <h1 className="head1">Корзина</h1>
          <div className={styles.cartText}>
            <strong>{cart?.length}</strong>
            <span>товара в Вашей корзине</span>
          </div>
        </div>

        <ItemInCart />
      </div>
      {renderBonusBox()}
      <div className="contain">
        {renderPriceBox()}
        {renderOrderButtons()}
        {renderForm()}
        <CarouselTopArrows
          headerText="Похожие товары"
          linkText="Смотреть все"
          link="/"
          img={feed}
          moveLeft
          text={text}
          arrayItems={similarProducts}
        />
      </div>
    </div>
  );
}
