import {
  ADD_TO_CART,
  CLEAR_CART,
  DELETE_FROM_CART,
  FETCH_CART_PRODUCTS,
  FETCH_CART_PRODUCTS_LOCALLY,
} from "../reducers/cartReducer";
import * as api from "../api";

export const fetchCartProducts = (config) => async (dispatch) => {
  try {
    const data = await api.fetchCartProducts(config);
    if (data.success)
      dispatch({ type: FETCH_CART_PRODUCTS, payload: data.data });
  } catch (error) {
    console.log(error);

    if (!config) dispatch({ type: FETCH_CART_PRODUCTS_LOCALLY });
  }
};

export const addToCart = (product, quantity = 1, config) => async (dispatch) => {
    try {
      let formData = { product_id: product?.id, quantity };
      const data = await api.addToCart(formData, config);

      if (data.success) {
        dispatch({ type: ADD_TO_CART, payload: { product, unit_quantity: quantity } });
      }
    } catch (error) {
      console.log(error);

      if (!config) dispatch({ type: ADD_TO_CART, payload: { product, unit_quantity: quantity } });
    }
  };

export const deleteFromCart = (product, config) => async (dispatch) => {
  try {
    let formData = { product_id: product?.id };
    const data = await api.deleteFromCart(formData, config);
    if (data.success) dispatch({ type: DELETE_FROM_CART, payload: product?.id });
  } catch (error) {
    console.log(error);
    if (!config) dispatch({ type: DELETE_FROM_CART, payload: product?.id });
  }
};

export const clearCart = (config) => async (dispatch) => {
  try {
    const data = await api.clearCart(config);
    // if (data.success);
    console.log("clear cart");
    dispatch({ type: CLEAR_CART });

    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({ type: CLEAR_CART });
  }
};
