import * as api from "../api"
import { FETCH_PROMOTIONS } from "../reducers/promotionsReducer"

export const fetchPromotions = () => async (dispatch) => {
  try {
    const data = await api.fetchPromotions()

    if (data.data)
      dispatch({ type: FETCH_PROMOTIONS, payload: data.data })
  } catch (error) {
    dispatch({ type: FETCH_PROMOTIONS })
  }
}