export const OPEN_LOGIN_BOX = 'OPEN_LOGIN_BOX'
export const CLOSE_LOGIN_BOX = 'CLOSE_LOGIN_BOX'
export const SWITCH_LOGIN_BOX = 'SWITCH_LOGIN_BOX'
export const OPEN_POPUP = 'OPEN_POPUP'
export const CLOSE_POPUP = 'CLOSE_POPUP'
export const OPEN_SERVICE_BUY = 'OPEN_SERVICE_BUY'
export const CLOSE_SERVICE_BUY = 'CLOSE_SERVICE_BUY'
export const OPEN_SUCCESS_POSTED = 'OPEN_SUCCESS_POSTED'
export const CLOSE_SUCCESS_POSTED = 'CLOSE_SUCCESS_POSTED'
export const OPEN_CALLBACK = 'OPEN_CALLBACK'
export const CLOSE_CALLBACK = 'CLOSE_CALLBACK'
export const OPEN_CHANGE_PASSWORD = 'OPEN_CHANGE_PASSWORD'
export const CLOSE_CHANGE_PASSWORD = 'CLOSE_CHANGE_PASSWORD'

export const modalReducer = (state, action) => {
  const defaultState = {
    login: {
      active: false,
      isLogin: true,
    },
    changePassword: {
      active: false,
    },
    serviceBuy: {
      serviceId: null,
      active: false,
    },
    callback: {
      active: false,
    },
    success: {
      active: false,
    },
    popup: {
      active: false,
    }
  }

  switch (action.type) {
    case OPEN_LOGIN_BOX:
      return { ...state, login: { active: true, isLogin: action.payload }};

    case CLOSE_LOGIN_BOX:
      return { ...state, login: defaultState.login }

    case SWITCH_LOGIN_BOX:
      return { ...state, login: { ...state.login, isLogin: !state.login.isLogin }}

    case OPEN_POPUP:
      return { ...state, popup: { active: true } }

    case CLOSE_POPUP:
      return { ...state, popup: defaultState.popup }

    case OPEN_SERVICE_BUY:
      return { ...state, serviceBuy: { active: true, serviceId: action.payload.serviceId } }

    case CLOSE_SERVICE_BUY:
      return { ...state, serviceBuy: defaultState.serviceBuy }

    case OPEN_SUCCESS_POSTED:
      return { ...state, success: { active: true } }

    case CLOSE_SUCCESS_POSTED:
      return { ...state, success: defaultState.success }

    case OPEN_CALLBACK:
      return { ...state, callback: { active: true } }

    case CLOSE_CALLBACK:
      return { ...state, callback: defaultState.callback }

    default:
      return state || defaultState;
  }
}