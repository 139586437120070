import { useEffect, useState } from "react";

import img from "../../assets/images/aqua.png";
import { FiArrowUpRight } from "react-icons/fi";
import * as api from "../../api";

import styles from "./s.module.css";
import Image from "../../utils/Image";

const bigText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

export default function ReferenceBooks() {
  const [manuals, setManuals] = useState([]);
  useEffect(() => {
    api.fetchManuals().then((val) => {
      if (val.success) setManuals(val.data);
    });
  }, []);

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Справочники</span>
        </div>
        <h1 className="head1">Справочники</h1>
        {manuals?.length > 0 &&
          manuals.map((manual, i) => (
            <div
              className={
                i % 2 === 0
                  ? styles.flexRows
                  : styles.flexRows + " " + styles.reverse
              }
              key={manual.name}
            >
              <div className={styles.my}>
                <h2 className={styles.head2}>{manual.name}</h2>
                {/* <p className={styles.mb2}>{manual.description}</p> */}
                <div
                  key={manual.name + i}
                  dangerouslySetInnerHTML={{
                    __html: manual.description,
                  }}
                />
                <div className={styles.center}>
                  <a href={manual.pdf} download>
                    <span>Справочник</span>
                    <i>
                      <FiArrowUpRight />
                    </i>
                  </a>
                </div>
              </div>
              <Image
                src={manual.thumbnail}
                alt="aqua"
                className={
                  i % 2 === 0
                    ? styles.imgBox2
                    : styles.imgBox2 + " " + styles.reverse
                }
              />
            </div>
          ))}
      </div>
    </div>
  );
}
