import { useEffect, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import tick from "../../../../../assets/icons/tickCircle/tickCircle.svg";
import tickRed from "../../../../../assets/icons/tickCircle/tickRed.svg";
import tickGreen from "../../../../../assets/icons/tickCircle/tickGreen.svg";
import { useCookies } from "react-cookie";
import { setBearerToken } from "../../../../../utils/req";

import styles from "../PersonalData.module.css";
import { sendResetPasswordLink } from "../../../../../api";
import { emailValidator } from "../../../../../utils/inputValidators";

const passwordText =
  "Введите Ваш email, изпользуемый для входа. Мы вышлем инструкцию по восстановлению пароля";

const arr = [
  "8 и более символов",
  "Прописные и строчные буквы",
  "Хотя бы одна цифра",
];

export default function ChangePassword({
  setOpenModal,
  restorePassword = false,
}) {
  const [modalPage, setModalPage] = useState(0);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [cookies, setCookie] = useCookies(["user"]);

  const [email, setEmail] = useState("");

  const tickColor = (index) => {
    if (password1.length === 0 && password2.length === 0) return tick;

    if (index === 0) {
      if (password1.length < 8) return tickRed;
      else return tickGreen;
    } else if (index === 1) {
      return tickGreen;
    } else if (index === 2) {
      if (!/\d/.test(password1)) return tickRed;
      else return tickGreen;
    }
  };

  const textColor = (index) => {
    if (password1.length === 0 && password2.length === 0) return "black";

    if (index === 0) {
      if (password1.length < 8) return "red";
      else return "green";
    } else if (index === 1) {
      return "green";
    } else if (index === 2) {
      if (!/\d/.test(password1)) return "red";
      else return "green";
    }
  };

  const onSendEmailLink = (e) => {
    e.preventDefault();
    setModalPage(1);
    if (emailValidator(email)) {
      let config = setBearerToken(cookies);

      // sendResetPasswordLink(email, config).then((val) => {
      //   if (val.success) {
      //     console.log("val = ", val);
      //   }
      // });
    }
  };

  return (
    <div className={styles.flexModal}>
      <div className={restorePassword ? styles.header : styles.topHeader}>
        <strong className={styles.head3}>Изменение пароля</strong>
        <i onClick={() => setOpenModal(false)}>
          <RiCloseFill />
        </i>
      </div>
      {modalPage === 0 && (
        <div>
          <p>{passwordText}</p>
          <div className={styles.textField}>
            <label>E-mail</label>
            <input
              type="text"
              placeholder="zhivoi.mir@gmail.com"
              onChange={(e) => {
                // e.preventDefault();
                setEmail(e.target.value);
              }}
            />
          </div>
          <button className="green-btn" onClick={onSendEmailLink}>
            Отправить
          </button>
        </div>
      )}
      {modalPage === 1 && (
        <div>
          <div className={styles.textField + " " + styles.mt}>
            <label>Новый пароль</label>
            <input
              type="password"
              placeholder="Новый пароль"
              onChange={(e) => setPassword1(e.target.value)}
            />
          </div>
          {arr.map((text, i) => (
            <div key={i} className={styles.rowField}>
              <img src={tickColor(i)} alt="tick" />
              <span style={{ color: textColor(i) }}>{text}</span>
            </div>
          ))}
          <div className={styles.textField + " " + styles.mt2}>
            <label>Повторите пароль</label>
            <input
              type="password"
              placeholder="Повторите пароль"
              onChange={(e) => setPassword2(e.target.value)}
            />
          </div>

          <button
            className="green-btn"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Сохранить
          </button>
        </div>
      )}
    </div>
  );
}
