export const DATA = [
  "Доставка по городу Алматы от 5000 бесплатная. При заказе на сумму меньше 5000 тг стоимость доставки просчитывается от дальности Вашего местонахождения. ",
  `Заказы принятые до 14-00 обрабатываются и доставляются день в день.
Заказы принятые после 14-00 доставляются на следующий день. 
с понедельника по пятницу только в квадрате улиц обозначенных на карте. Карта бесплатной доставки здесь.
Минимальная сумма заказа для бесплатной доставки составляет 5000 тенге.
Если при получении заказа клиент отказывается от части заказа и сумма к оплате становится ниже указанной, либо отказывается от заказа полностью - клиент оплачивает стоимость доставки 500 тенге. 
Заказы, оформленные в пятницу, субботу и воскресенье, доставляются в понедельник или вторник.
В субботу и воскресенье доставка не работает.`,
  `Внимание!
Доставка осуществляется в указанный промежуток времени по очереди в соответствии с маршрутом. Курьер назад не возвращается. 
Перед доставкой курьер с вами созванивается. Если телефоны для связи указаны вами неверно, отключены или по каким-либо другим причинам курьер не может с вами связаться – заказ отменяется до вашего звонка в офис.
Доставка крупногабаритных товаров производится до ближайшей точки проезда автомобиля курьера по адресу доставки. Подъем на этаж осуществляется при помощи со стороны покупателя.
Подъем на этаж тяжеловесных заказов (общий вес свыше 50 кг) осуществляется платно из расчета 200 тенге за этаж, либо силами покупателя.`,

  `Самовывоз
Заказы на самовывоз комплектуются в течении 1 рабочего дня.
Затем с Вами свяжется наш менеджер и согласует дату самовывоза.
Адрес точки самовывоза: Толе би 160 (угол ул Ауэзова) 
Товар хранится в офисе не более двух рабочих дней.`,

  `Доставка по Казахстану
Порядок работы с региональными клиентами:
Вы оформляете заказ на нашем сайте.
Товары из вашего заказа проверяются на наличие в течение 24 часов и резервируются на три рабочих дня.
После этого рассчитываются способы доставки и сумма к оплате. Информация высылается вам на электронную почту.
Далее вам необходимо оплатить заказ банковской картой по реквизитам, которые вам были высланы по электронной почте.
Как только заказ укомплектован (при условии поступления на наш счет оплаты) – он отправляется в транспортную компанию. После чего вам направляется уведомление об отправке.`,
];

export const DATA2 = [
  `Наличными курьеру при доставке.
Оплата онлайн любой банковской картой на карту каспи банка.
Оплата переводом или Каспи пей при получении заказа. (только для заказов с г. Алматы)
Регионы Казахстана:
Оплата любой банковской картой на карту каспи банка. (по номеру телефона, номеру карты или иин, выставлением счета каспи пей) `,
];

export const DATA3 = [
  `Suscipit adipiscing bibendum est ultricies. Dolor purus non enim praesent elementum. Etiam erat velit scelerisque in dictum non. Amet dictum sit amet justo donec enim. At tempor commodo ullamcorper a. Ac orci phasellus egestas tellus rutrum.`,
];

export const DATA4 = [
  `Id aliquet risus feugiat in ante metus. A condimentum vitae sapien pellentesque. Arcu risus quis varius quam quisque id diam. Urna nec tincidunt praesent semper feugiat nibh sed. Magna sit amet purus gravida quis. Nibh tortor id aliquet lectus. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque.`,
];

export const DATA5 = [
  `Уважаемые клиенты! 
Проверяйте, пожалуйста, комплектность, целостность и годность доставленного вам товара в присутствии курьера!  
При обнаружении недостачи товара или его избытка вы вправе отказаться от заказа или заплатить за ту его часть, которая соответствует вашему заказу. Также вы вправе отказаться от любого неподходящего или не понравившегося товара. При этом вы обязаны оплатить транспортные расходы в размере стоимости доставки. 
Возврат непродовольственного товара надлежащего качества проводится, если указанный товар не был в употреблении, сохранены его товарный вид, потребительские свойства, пломбы, фабричные ярлыки в течение 14 (четырнадцати) календарных дней с момента передачи товара покупателю. Возврат или обмен товара производится через офис по адресу: г. Алматы, ул. Толе би 160 (при предварительном согласовании). 
Согласно законодательства РК, некоторые товары надлежащего качества замене или возврату не подлежат (живой товар, продовольственные товары и т.д.). Уточняйте у наших операторов. 
Уцененные товары, а также товары, проданные по распродажной цене, возврату и обмену не подлежат. 
Оформив заказ, вы автоматически соглашаетесь с вышеизложенными правилами магазина.`,
];
