import { useSelector } from "react-redux";

import CarouselTopArrows from "../../components/CarouselTopArrows/CarouselTopArrows";

import feed from "../../assets/icons/feed.svg";
import dogGang from "../../assets/images/discounts/dogGang.png";
import border from "../../assets/images/discounts/border.svg";
import dogFeed from "../../assets/images/discounts/dogFeed.png";

import styles from "./discounts.module.css";
import { useEffect, useState } from "react";
import { fetchItemsByCategory } from "../../api";
import Image from "../../utils/Image";
const head1 = "При покупке товара на сумму";

const text =
  "Сухой корм для взрослых собак средних пород крупных пород с ягненком и рисом";
const head2 = "Данная система скидок";
const text2 = "Не распространяется на корма для кошек и собак!";

export default function Discounts() {
  const sales = useSelector((state) => state.sales);
  const [allHitProducts, setAllHitProducts] = useState([]);
  useEffect(() => {
    fetchItemsByCategory({
      params: { novelty: 0, hit: 1, discounted: 0 },
    }).then((json) => {
      if (json.success) setAllHitProducts(json.data.products);
    });
  }, []);

  function renderSales() {
    const saleText = ({sale_started_from , discount_percentage}) => {
      return `От ${sale_started_from} тг - ${discount_percentage}%`
    }

    const firstSale = () => {
      if (sales[0]) {
        return (
          <div className={styles.card1}>
            <Image src={sales[0].thumbnail} />
            <div className={styles.textOnBox}>
              <p>{head1}</p>
              <strong>{saleText(sales[0])}</strong>
            </div>
          </div>
        )
      }
    }

    const secondSale = () => {
      if (sales[1]) {
        return (
          <div className={styles.card2}>
            <Image src={sales[1].thumbnail} />
            <div className={styles.textOnBox}>
              <p>{head1}</p>
              <strong>{saleText(sales[1])}</strong>
            </div>
          </div>
        )
      }
    }

    const thirdSale = () => {
      if (sales[2]) {
        return (
          <div className={styles.card2}>
            <Image src={sales[2].thumbnail} />
            <div className={styles.textOnBox}>
              <p>{head1}</p>
              <strong>{saleText(sales[2])}</strong>
            </div>
          </div>
        )
      }
    }
    return (
      <div className={styles.flex}>
        {firstSale()}
        <div className={styles.columnCards}>
          {secondSale()}
          {thirdSale()}
        </div>
      </div>
    )
  }

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Скидки</span>
        </div>
        <h1 className="head1">Скидки</h1>
        {renderSales()}
        <div className={styles.exceptionBanner}>
          <div className={styles.dogBox}>
            <img src={dogGang} alt="dogs" />
          </div>
          <div className={styles.border}>
            <img src={border} alt="border" />
          </div>

          <div className={styles.text}>
            <h3>{head2}</h3>
            <p>{text2}</p>
          </div>

          <div className={styles.dogFeed}>
            <img src={dogFeed} alt="dog feed" />
          </div>
        </div>

        <CarouselTopArrows
          headerText="Хиты продаж"
          linkText="Смотреть все"
          link="/catalog/?hit=1"
          img={feed}
          text={text}
          arrayItems={allHitProducts}
          moveLeft
        />
      </div>
    </div>
  );
}
