import {
  ADD_TO_FAVORITE,
  DELETE_FROM_FAVORITE,
  FETCH_FAVORITE_PRODUCTS,
} from "../reducers/favoriteReducer";

export const fetchFavoriteProducts = () => async (dispatch) => {
  try {
    // const data = [];
    dispatch({ type: FETCH_FAVORITE_PRODUCTS });
  } catch (error) {
    console.log(error);
  }
};

export const addToFavorite = (product) => async (dispatch) => {
  try {
    // const data = [];
    dispatch({ type: ADD_TO_FAVORITE, payload: product });
  } catch (error) {
    console.log(error);
  }
};

export const deleteFromFavorite = (product) => async (dispatch) => {
  try {
    // const data = [];
    dispatch({ type: DELETE_FROM_FAVORITE, payload: product.id });
  } catch (error) {
    console.log(error);
  }
};
