import { useSelector, useDispatch } from 'react-redux'
import { closeSuccessPosted } from '../../actions/modalAction'
import ModalView from './View'

import tick from "../../assets/images/account/checked.png";

export default function SuccessPostedModal() {
  const dispatch = useDispatch()
  const { active } = useSelector((state) => state.modal.success)

  return (
    <ModalView
      active={active}
      setActive={() => dispatch(closeSuccessPosted())}
    >
      <div className="textCenter">
        <img src={tick} alt="tick" />
        <h4>Ваша заявка оформлена.</h4>
        <p>В ближайшее время менеджер с вами свяжется</p>
      </div>
    </ModalView>
  )
}