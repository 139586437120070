import { useCookies } from "react-cookie";
import { MdFavoriteBorder } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import { addToCart, deleteFromCart } from "../../../actions/cartAction";
import {
  addToFavorite,
  deleteFromFavorite,
} from "../../../actions/favoritesAction";
import { setBearerToken } from "../../../utils/req";

import styles from "./feed.module.css";
import Image from "../../../utils/Image";

export default function FeedCard({
  id,
  variations,
  title,
  itemType,
  text,
  prevPrice,
  price,
  img,
  bannerColor,
  bannerText,
  wrapNumber = 4,
  moveLeft,
}) {
  const [cookies, setCookie] = useCookies(["user"]);
  const config = setBearerToken(cookies);

  const cartProducts = useSelector((state) => state.cart);
  const favoriteProducts = useSelector((state) => state.favorite);
  const dispatch = useDispatch();
  const product = {
    id,
    variations,
    itemType,
    title,
    text,
    prevPrice,
    price,
    img,
    unit_quantity: 1
  };

  const productInCart = () => {
    return cartProducts?.find((cartProduct) => cartProduct?.product?.id === product?.id)
  }

  return (
    <NavLink
      to={`/${itemType}/${id}`}
      className={
        moveLeft
          ? styles.card + " " + styles.moveLeft
          : wrapNumber === 4
          ? styles.card + " " + styles.width4
          : wrapNumber === 1
          ? styles.card
          : styles.card + " " + styles.width3
      }
    >
      <div className={styles.feedCard}>
        <div className={styles.feedImg}>
          <Image src={img} alt="feed" />
        </div>
        <strong className={styles.title}>{title}</strong>
        <p className={styles.cardDescription}>{text}</p>
        <div className={styles.flexCenter}>
          {prevPrice && <div className={styles.prevPrice}>{prevPrice} ₸</div>}
          <span className={styles.price}>{price} ₸</span>
        </div>

				<div className={styles.flexCenter}>
          <button
            className={"green-btn " + styles.paddingBtn}
            onClick={(e) => {
              e.preventDefault();
              if (productInCart()) {
                dispatch(deleteFromCart(product, config));
              } else {
                dispatch(addToCart(product, 1, config));
              }
            }}
          >
            <svg
              viewBox="0 0 33 33"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.cart}
            >
              <path
                d="M5.39075 30.8285L6.41671 30.3155L6.41671 30.3155L5.39075 30.8285ZM27.021 30.8285L28.047 31.3415L28.047 31.3415L27.021 30.8285ZM1.65273 12.9235H30.759V10.6294H1.65273V12.9235ZM30.0471 12.2115V13.151H32.3412V12.2115H30.0471ZM26.6675 29.9H5.7443V32.1941H26.6675V29.9ZM2.36471 13.151V12.2115H0.0705925V13.151H2.36471ZM6.41671 30.3155C3.752 24.9861 2.36471 19.1095 2.36471 13.151H0.0705925C0.0705925 19.4656 1.5408 25.6935 4.36479 31.3415L6.41671 30.3155ZM5.7443 29.9C6.02906 29.9 6.28937 30.0609 6.41671 30.3155L4.36479 31.3415C4.62605 31.864 5.1601 32.1941 5.7443 32.1941V29.9ZM25.9951 30.3155C26.1224 30.0609 26.3827 29.9 26.6675 29.9V32.1941C27.2517 32.1941 27.7857 31.864 28.047 31.3415L25.9951 30.3155ZM30.0471 13.151C30.0471 19.1095 28.6598 24.9861 25.9951 30.3155L28.047 31.3415C30.871 25.6935 32.3412 19.4656 32.3412 13.151H30.0471ZM30.759 12.9235C30.3658 12.9235 30.0471 12.6047 30.0471 12.2115H32.3412C32.3412 11.3377 31.6328 10.6294 30.759 10.6294V12.9235ZM1.65273 10.6294C0.778938 10.6294 0.0705925 11.3377 0.0705925 12.2115H2.36471C2.36471 12.6047 2.04595 12.9235 1.65273 12.9235V10.6294ZM8.62478 12.3666L15.0483 1.66071L13.0811 0.4804L6.65759 11.1863L8.62478 12.3666ZM17.3635 1.66071L23.787 12.3666L25.7542 11.1863L19.3307 0.4804L17.3635 1.66071Z"
                fill="#FFF"
              />
            </svg>
            {productInCart()
              ? "Убрать с корзины"
              : "В корзину"}
          </button>
        </div>

        {bannerText && (
          <div className={styles.banner + " " + bannerColor}>{bannerText}</div>
        )}
        <div
          className={styles.favorite}
          onClick={(e) => {
            e.preventDefault();

            if (
              favoriteProducts?.find(
                (favoriteProduct) => favoriteProduct?.id === product?.id
              )
            )
              dispatch(deleteFromFavorite(product));
            else dispatch(addToFavorite(product));
          }}
        >
          {favoriteProducts?.find(
            (favoriteProduct) => favoriteProduct?.id === product?.id
          ) ? (
            <MdFavorite />
          ) : (
            <MdFavoriteBorder />
          )}
        </div>
      </div>
    </NavLink>
  );
}
