import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import map from "../../assets/icons/map.svg";

import { IoIosMail } from "react-icons/io";
import { ImPhone } from "react-icons/im";
import { BsGeoAlt } from "react-icons/bs";
import InputMask from "react-input-mask";

// import { YMaps, Map } from "react-yandex-maps";
import { fetchFilials, fetchCities, postRequestOnFeedback } from "../../api";
import { openSuccessPosted } from '../../actions/modalAction'
import DG from "2gis-maps";

import styles from "./contact.module.css";

export default function ContactPage() {
  const dispatch = useDispatch()
  const [contacts, setContacts] = useState([]);
  const [formData, setFormData] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [cities, setCities] = useState([]);
  const [maps, setMaps] = useState([]);
  const [selectedCity, setSelectedCity] = useState();

  useEffect(() => {
    DG.then(function () {
      let map = DG.map("map", {
        center: [54.98, 82.89],
        zoom: 13,
      });

      DG.marker([54.98, 82.89]).addTo(map).bindPopup("Вы кликнули по мне!");
    });

    fetchFilials().then((val) => {
      if (val.success) setContacts(val.data);
    });

    fetchCities().then((val) => {
      if (val.success) {
        setCities(val.data);
        if (val.data.length > 0) setSelectedCity(val.data[0].name);
      }
    });
  }, []);

  useEffect(() => {
    contacts.forEach(({ coordinates, address }, i) => {
      try {
        DG.then(function () {
          let map = DG.map("map" + i, {
            center: coordinates,
            zoom: 13,
          });
          DG.marker(coordinates).addTo(map).bindPopup(address);
        });
      } catch (error) {
        console.log("err = ", error);
      }
    });
  }, [contacts]);

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;

    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onSubmitForm = (e) => {
    setErrMsg('')

    e.preventDefault();
    let newFormData = {
      ...formData,
      phone: "+" + formData?.phone?.replace(/[^\d]/g, ""),
      city_id: 1,
    };

    postRequestOnFeedback(newFormData)
      .then((json) => {
        console.log("json = ", json);
        if (json.success) {
          dispatch(openSuccessPosted())
        }
      })
      .catch((_) => {
        setErrMsg("Вы ввели данные некорректно...");
      });
  };

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Контакты</span>
        </div>
        <h1 className="head1">Контакты</h1>

        <div className="flex-wrap">
          {/* <div className={styles.card}>
            <div className={styles.imgBox}>
              <img src={map} alt="map"  />
            </div>
            <div className={styles.textColumn}>
              <h3>Астана</h3>
              <div className={styles.row}>
                <i>
                  <IoIosMail />
                </i>
                <span className={styles.emailText}>order@zhivoimir.kz</span>
              </div>

              <div className={styles.row}>
                <i>
                  <ImPhone />
                </i>
                <div>
                  <div className={styles.phoneText}>
                    <small>Торговый зал</small>
                    <strong>+7 (903) 154-70-11</strong>
                  </div>

                  <div className={styles.phoneText}>
                    <small>Доставка</small>
                    <strong>+7 (903) 154-70-11</strong>
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <i>
                  <BsGeoAlt />
                </i>
                <span className={styles.addressText}>Ул. Ленина, д 53</span>
              </div>
            </div>
          </div> */}

          {contacts.map((contact, i) => (
            <div className={styles.card} key={contact.city + i}>
              <div
                // className={styles.imgBox}
                className={styles.cardMap}
              >
                <div
                  id={"map" + i}
                  style={{ height: "100%", width: "100%" }}
                ></div>
              </div>
              <div className={styles.textColumn}>
                <h3>{contact.city.name}</h3>
                <div className={styles.row}>
                  <i>
                    <IoIosMail />
                  </i>
                  <span className={styles.emailText}>{contact.email}</span>
                </div>

                <div className={styles.row}>
                  <i>
                    <ImPhone />
                  </i>

                  {Object.keys(contact.phones).map((key) => {
                    return (
                      <div className={styles.phoneText}>
                        <small>{key}</small>
                        <strong>{contact.phones[key]}</strong>
                      </div>
                    )
                  })}
                </div>
                <div className={styles.row}>
                  <i>
                    <BsGeoAlt />
                  </i>
                  <span className={styles.addressText}>{contact.address}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.map}>
        {/* <YMaps>
          <Map
            defaultState={{ center: [43.22, 76.85], zoom: 13 }}
            width="100%"
            height="40vh"
          />
        </YMaps> */}
        <div id="map"></div>
      </div>

      <div className="contain">
        <h2 className={styles.head2}>Форма для обратной связи</h2>

        <div className={styles.contactBox}>
          <div className="flex-wrap">
            <input
              name="name"
              className={styles.textField}
              placeholder="Имя"
              onChange={handleChange}
            />
            <InputMask
              name="phone"
              placeholder="+7 (707) 746 47 94"
              mask="+7 (999) 999-99-99"
              maskChar="_"
              onChange={handleChange}
              className={styles.textField}
            />
            <input
              name="email"
              className={styles.textField}
              placeholder="Email"
              onChange={handleChange}
            />
            <input
              name="city_id"
              className={styles.textField}
              placeholder="Город"
              onChange={handleChange}
            />
            <textarea
              name="message"
              className={styles.textField}
              placeholder="Сообщение"
              rows="8"
              onChange={handleChange}
            />
            {errMsg && <div className={styles.errMsg}>{errMsg}</div>}
            <button className="green-btn" onClick={onSubmitForm}>
              Отправить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
