export const FETCH_SERVICES = "FETCH_SERVICES";
export const ORDER_SERVICE = "ORDER_SERVICE";

export const servicesReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_SERVICES:
      return action?.payload || [];
    default:
      return state;
  }
}