export const TABS = [
  "Ut enim ad minim veniam, quis nostrud commodo consequat?",
  "Ut enim ad minim veniam, quis nostrud commodo consequat?",
  "Ut enim ad minim veniam, quis nostrud commodo consequat?",
  "Ut enim ad minim veniam, quis nostrud commodo consequat?",
];

export const TAB_VIEWS = {
  header: "Ut enim ad minim veniam, quis nostrud commodo consequat?",
  body: "Feugiat nisl pretium fusce id. Sed risus ultricies tristique nulla aliquet enim. Purus in mollis nunc sed id semper risus in hendrerit. Fermentum odio eu feugiat pretium nibh ipsum consequat nisl vel. Cursus euismod quis viverra nibh cras pulvinar mattis. Semper quis lectus nulla at.",
};
