import { NavLink } from "react-router-dom";

import styles from "./animal.module.css";
import Image from "../../../utils/Image";

export default function AnimalCard({ title, categories = [], img, animalImg, alt, id }) {
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <strong>{title}</strong>
      </div>
      <div className={styles.flex}>
        <Image
          className={animalImg ? styles.animalImg : undefined}
          src={img}
          alt={alt}
        />

        <div className={styles.textColumn}>
          {categories.map((category, i) => (
            <span>
              <NavLink key={i} to={"/catalog?category_id=" + category.id}>
                {category.name}
              </NavLink>
            </span>
          ))}
          <NavLink to={id} className="blueUnderline">
            Все категории
          </NavLink>
        </div>
      </div>
    </div>
  );
}
