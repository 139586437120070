import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import InputMask from "react-input-mask";
import { useCookies } from "react-cookie";
import { closeLoginBox , switchLoginBox, openChangePassword } from '../../actions/modalAction'

import tick from "../../assets/icons/tick.svg";

import ModalView from './View'
import { getUserToken, signup, getUser } from "../../api";
import { addToCart } from "../../actions/cartAction";
import { getUserData } from "../../actions/auth";
import { setBearerToken } from "../../utils/req";
import { CLEAR_CART } from "../../reducers/cartReducer";

import styles from "./login.module.css";

export default function Popup() {
  const dispatch = useDispatch()
  const { active, isLogin } = useSelector((state) => state.modal.login)
  const cart = useSelector((state) => state.cart);
  const [cookies, setCookie] = useCookies(["user"]);
  const [formData, setFormData] = useState({ surname: "surname" });
  const [errorMsg, setErrorMsg] = useState("");

  const authUser = (data) => {
    return getUserToken(data)
      .then((val) => {
        let token = val.token.split("|")[1];
        let date = new Date();

        date.setTime(date.getTime() + 60 * 1000 * 120);

        setCookie("session_id", "s%" + token, {
          expires: date,
        });

        const config = setBearerToken({ session_id: "s%" + token })

        dispatch(getUserData(config)).then(() => {
          dispatch({ type: CLEAR_CART });
          cart.forEach((product) => {
            dispatch(addToCart(product.product, product.unit_quantity, config));
          })

          dispatch(closeLoginBox())
        })
      })
      .catch((error) => {
        setErrorMessage(error)
      });
  }
  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;

    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    setErrorMsg([]);

    if (isLogin)
      authUser(formData)
    else {
      const newFormData = formData
      const { phone } = formData
      if (phone) {
        newFormData.phone = "+" + phone.replace(/[^\d]/g, "")
      }

      signup(newFormData)
        .then((val) => {
          if (val.success) {
            authUser(newFormData)
          }
        })
        .catch((error) => {
          setErrorMessage(error);
        });
    }
  };

  const setErrorMessage = (error) => {
    const { data, message } = error.response?.data;
    const messages = [];

    if (!data && message) {
      messages.push(message);
    } else {
      Object.entries(data).forEach(([key, errors]) => {
        errors.forEach((message) => {
          messages.push(message);
        })
      })
    }

    setErrorMsg(messages);
  }

  const renderErrors = (messages) => {
    if (!messages && !messages.length) {
      return;
    }

    return (
      <React.Fragment>
        {
          messages.map((message) => {
            return <div className={styles.errorMsg}>{message}</div>
          })
        }
      </React.Fragment>
    )
  }

  const renderForm = () => {
    return (
      <div onClick={(e) => e.stopPropagation()} className={styles.loginContent}>
        <div className={styles.header}>{isLogin ? "Войти" : "Регистрация"}</div>
        <div className={styles.body}>
          {!isLogin && (
            <div className={styles.textBox}>
              <p className={styles.label}>Имя</p>
              <input
                name="name"
                placeholder="Михаил"
                type="text"
                onChange={handleChange}
                className={styles.textField}
              />
            </div>
          )}
          <div className={styles.textBox}>
            <p className={styles.label}>Электронная почта</p>
            <input
              name="email"
              placeholder="m.kasanov@gmail.com"
              type="text"
              onChange={handleChange}
              className={styles.textField}
            />
          </div>
          <div className={styles.textBox}>
            <p className={styles.label}>Пароль</p>
            <input
              name="password"
              placeholder="*********"
              type="password"
              onChange={handleChange}
              className={styles.textField}
            />
          </div>
          {isLogin && (
            <p
              className={styles.forgotPass}
              onClick={() => {
                dispatch(closeLoginBox())
                dispatch(openChangePassword())
              }}
            >
              Забыли пароль?
            </p>
          )}
          {!isLogin && (
            <React.Fragment>
              <div className={styles.textBox}>
                <p className={styles.label}>Подтвердите пароль</p>
                <input
                  name="password_confirmation"
                  placeholder="*********"
                  type="password"
                  onChange={handleChange}
                  className={styles.textField}
                />
              </div>

              <div className={styles.textBox}>
                <p className={styles.label}>Телефон</p>
                {/* backslash before number to show it */}
                <InputMask
                  name="phone"
                  placeholder="+7 (707) 746 47 94"
                  mask="+7 (999) 999-99-99"
                  maskChar="_"
                  onChange={handleChange}
                  className={styles.textField}
                />
              </div>

              <div className={styles.flex}>
                <div className={styles.checkBox}>
                  <img src={tick} alt="tick" />
                </div>
                <div className={styles.greyText}>
                  Даю согласие на обработку своих персональных данных
                </div>
              </div>
            </React.Fragment>
          )}
          {renderErrors(errorMsg)}
          <div className={styles.btnBox}>
            <button
              className="green-btn"
              onClick={(e) => {
                onSubmitForm(e);
                // closeModal();
              }}
            >
              {isLogin ? "Войти" : "Регистрация"}
            </button>
            <span className={styles.greyText}>или</span>
            <div
              className="blueUnderline"
              onClick={() => {
                dispatch(switchLoginBox())
                setErrorMsg([])
              }}
            >
              {isLogin ? "Регистрация" : "Войти"}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        active
          ? styles.loginBox + " " + styles.active
          : styles.loginBox
      }
      onClick={(e) => {
        e.stopPropagation();
        dispatch(closeLoginBox())
      }}
    >
      {renderForm()}
    </div>
  )
}