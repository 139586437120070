export const setBearerToken = (cookies) => {
  const { session_id } = cookies;

  if (!session_id) return;

  let token = session_id.split("%")[1];
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
};
