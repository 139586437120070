import Pagination from "../../components/Pagination";

import productionImg from "../../assets/images/production.jpg";
import aquarium from "../../assets/images/aquarium2.jpg";
import styles from "./work.module.css";
import { useEffect, useState } from "react";
import { fetchCompanyWorks } from "../../api";
import Image from "../../utils/Image";

const headText = "Наши дизайнерские работы по оформлению аквариумов";

export default function WorkPage() {
  const [works, setWorks] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [paginatedWorks, setPaginatedWorks] = useState([]);
  const [page, setPage] = useState(0);

  const images = works.length ? works[0].images : [];
  const textBlock = works.length ? works[0].block : '';
  const perPageItems = 3;
  const pageCount = Math.ceil(works?.length / perPageItems);

  const paginateWorks = () => {
    let offset = (page - 1) * perPageItems;
    let newArr = works?.slice(offset).slice(0, perPageItems);
    setPaginatedWorks(newArr);
  };

  useEffect(() => {
    if (!loaded)
      fetchCompanyWorks()
        .then((json) => {
          if (json.success) setWorks(json.data);
        })
        .finally(() => setLoaded(true));

    paginateWorks();
  }, [page, works]);

  function renderImages() {
    return (
			<div className={styles.aquariumContainer}>
        {images.map((image) => {
          return (
						<div className={styles.aquariumBox}>
							<Image src={image} />
						</div>
          )
        })}
			</div>
    )
	}

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Наши работы</span>
        </div>
        <h1 className="head1">Наши работы</h1>
        <h1 className="head1">Производство продукции</h1>

        <div className={styles.grid}>
          {paginatedWorks.map((work, i) => {
            return (
              <div
                className={
                  i % 3 === 0
                    ? styles.column1
                    : i % 3 === 1
                    ? styles.column2
                    : undefined
                }
                key={work.name}
              >
                <Image src={work.image || work.thumbnail || work.img || productionImg} />

                <h3 className={styles.headText}>{work.name}</h3>
                <p className={styles.bigText}>{work.description}</p>
              </div>
            );
          })}
          {/* <div className={styles.column1}>
            <img src={productionImg} alt="production" />

            <h3 className={styles.headText}>Заголовок</h3>
            <p className={styles.bigText}>{bigText}</p>
          </div>

          <div className={styles.column2}>
            <img src={productionImg} alt="production" />

            <h3 className={styles.headText}>Заголовок</h3>
            <p className={styles.bigText}>{bigText}</p>
          </div>

          <div>
            <img src={productionImg} alt="production" />

            <h3 className={styles.headText}>Заголовок</h3>
            <p className={styles.bigText}>{bigText}</p>
          </div> */}
        </div>

        {works.length > 0 && (
          <Pagination length={pageCount} onClick={(i) => setPage(i)} />
        )}

        <div className={styles.worksBox}>
          <div className={styles.header}>
            <h2>{headText}</h2>
            <p>{textBlock}</p>
          </div>
          {renderImages()}
        </div>
      </div>
    </div>
  );
}
