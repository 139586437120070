import { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { HiOutlinePhone } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { MdFavoriteBorder } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { searchItem } from "../../../api";
import logo from "../../../assets/icons/logo.svg";
import cart from "../../../assets/icons/cart.svg";

import styles from "./mobile.module.css";

import {
  LEFT_NAVBAR,
  PHONES,
  RIGHT_NAVBAR,
  TOP_NAVBAR,
} from "../../../utils/const/home";

const animatedText = "Поиск анимированный";
const speed = 220;
let i = 0;

export default function MobileNavbar({ openLoginBox, loggedIn, openBoxCall }) {
  const history = useHistory();
  const cartProducts = useSelector((state) => state.cart);
  const favoriteProducts = useSelector((state) => state.favorite);
  const [menuClicked, setMenuClicked] = useState(false);
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const [path, setPath] = useState("/");
  const authData = useSelector((state) => state.auth?.data);
  const location = useLocation();

  const inputRef = useRef(null);

  const closeNav = (link) => {
    setPath(link)
    setMenuClicked(false)
  };

  useEffect(() => {
    setPath(location.pathname)
  }, [location])

  useEffect(() => {
    if (inputRef) type();
  }, [inputRef?.current?.value]);

  const onSearchBox = (query) => {
    searchItem(query).then((val) => {
      console.log("val = ", val);
    });

    history.push({ pathname: "/catalog", search: `?search_query=${query}` });
  };

  function type() {
    if (!inputRef) return;
    if (inputRef?.current?.value?.length > 0) {
      inputRef.current.placeholder = "";
      i = 0;
      return;
    }

    if (i === animatedText.length) reverseType();
    else if (inputRef?.current) {
      let placeholder = inputRef.current.placeholder;
      inputRef.current.placeholder = placeholder + animatedText.charAt(i);

      i++;
    }

    setTimeout(type, speed);
  }

  function reverseType() {
    if (inputRef?.current?.placeholder.length > 0) {
      let placeholder = inputRef.current.placeholder;
      inputRef.current.placeholder = placeholder.substring(
        0,
        placeholder.length - 1
      );
    } else i = 0;
  }

  const styleActive = (link) => {
    return path === link ? styles.active : ''
  }

  return (
    <div className={styles.navbarBox}>
      <div className={styles.left}>
        <i onClick={() => history.push("/contact")}>
          <HiOutlinePhone />
        </i>
        <i onClick={() => setOpenMobileSearch(true)}>
          <BiSearch />
        </i>
      </div>
      <NavLink to="/">
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
      </NavLink>
      <div className={styles.right}>
        {menuClicked ? (
          <i onClick={() => setMenuClicked((e) => !e)}>
            <IoClose />
          </i>
        ) : (
          <i onClick={() => setMenuClicked((e) => !e)}>
            <AiOutlineMenu />
          </i>
        )}
      </div>

      <div
        className={
          menuClicked ? styles.navbar + " " + styles.active : styles.navbar
        }
      >
        <img src={logo} alt="logo" className={styles.absLogo} />

        <div
          className={`${styles.catalog} ${styleActive("/catalog")}`}
          onClick={() => {
            closeNav("/catalog");
            history.push("/catalog");
          }}
        >
          <i>
            <AiOutlineMenu />
          </i>
          Каталог
        </div>
        {LEFT_NAVBAR.map((link, i) => (
          <p
            key={i}
            className={`${styles.catalog} ${styles.menu} ${styleActive(link.to)}`}
          >
            <NavLink to={link.to} onClick={() => closeNav(link.to)}>
              {link.name}
            </NavLink>
          </p>
        ))}
        {RIGHT_NAVBAR.map((link, i) => (
          <p
            key={i}
            className={`${styles.catalog} ${styles.menu} ${styleActive(link.to)}`}
            onClick={() => closeNav(link.to)}
          >
            <NavLink to={link.to}>{link.name}</NavLink>
          </p>
        ))}
        {TOP_NAVBAR.map((link, i) => (
          <p
            key={i}
            className={`${styles.catalog}${styles.menu}  ${styleActive(link.to)}`}
            onClick={() => closeNav(link.to)}
          >
            <NavLink to={link.to}>{link.name}</NavLink>
          </p>
        ))}

        <div className={styles.line}></div>
        <div
          className={styles.iconBox}
          onClick={() => {
            history.push("/favorites");
            closeNav();
          }}
        >
          <i className={styles.icon}>
            <MdFavoriteBorder />
            {favoriteProducts.length > 0 && (
              <span className={styles.number + " " + styles.positioned}>
                {favoriteProducts.length}
              </span>
            )}
          </i>
          Избранное
        </div>
        <div
          className={styles.iconBox}
          onClick={() => {
            history.push("/cart");
            closeNav();
          }}
        >
          <i className={styles.icon}>
            <img src={cart} alt="" />
            {cartProducts.length > 0 && (
              <span className={styles.number}>{cartProducts.length}</span>
            )}
          </i>
          Корзина
        </div>

        <div className={styles.iconBox + " " + styles.phone}>
          <i className={styles.icon}>
            <HiOutlinePhone />
          </i>
          <div>
            {PHONES.map((link, i) => (
              <p key={i}>{link}</p>
            ))}
            <p
              className={styles.underline}
              style={{ marginLeft: "-20%" }}
              onClick={() => {
                openBoxCall();
                closeNav();
              }}
            >
              Заказать звонок
            </p>
          </div>
        </div>

        {loggedIn ? (
          <NavLink to="/account" onClick={closeNav}>
            <div className={styles.signInIcon}>
              <BsPerson />
              <span style={{ marginLeft: "0.2rem" }}>{authData?.name}</span>
            </div>
          </NavLink>
        ) : (
          <button className={styles.btn} onClick={openLoginBox}>
            <BsPerson className={styles.personIcon} />
            Вход
          </button>
        )}
      </div>

      <div
        className={styles.mobileSearchPage}
        style={{
          opacity: openMobileSearch ? 1 : 0,
          pointerEvents: openMobileSearch ? "all" : "none",
        }}
      >
        <div className={styles.searchPageContent}>
          <input type="text" ref={inputRef} className={styles.searchBox} />
          <i
            onClick={() => {
              setOpenMobileSearch(false);
            }}
          >
            <IoClose />
          </i>
        </div>
      </div>
    </div>
  );
}
