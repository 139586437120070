import { IoClose } from "react-icons/io5";
import QuantityBox from "../../QuantityBox";
import { useDispatch } from "react-redux";
import { addToCart, deleteFromCart } from "../../../actions/cartAction";

import { useSelector } from "react-redux";
import styles from "./item.module.css";
import {setBearerToken} from "../../../utils/req";
import { useCookies } from "react-cookie";
import Image from "../../../utils/Image";

export default function ItemInCart() {
  const [cookies, setCookie] = useCookies(["user"]);
  const cart = useSelector((state) => state.cart);
  const config = setBearerToken(cookies);

  const dispatch = useDispatch();

  const onDelete = (item) => {
    if (cart.find((cartItem) => cartItem?.product?.id === item?.product?.id)) {
      dispatch(deleteFromCart(item.product, config));
    }
  };

  const setQuantity = (item, isAdding, q) => {
    if (q) {
      return dispatch(addToCart(item?.product, q, config))
    }

    const quantity = isAdding ? item.unit_quantity + 1 : item.unit_quantity - 1

    dispatch(addToCart(item?.product, quantity, config))
  }

  return (
    <div>
      <div className={styles.desktopPart}>
        <div className={styles.grid}>
          <div className={styles.firstColumn}>
            <strong>Товар</strong>
          </div>
          {/*<div className={styles.secondColumn}>*/}
            {/*<strong>Размер</strong>*/}
          {/*</div>*/}
          <div className={styles.thirdColumn}>
            <strong>Количество</strong>
          </div>
          <div className={styles.fourthColumn}>
            <strong>Цена</strong>
          </div>
        </div>

        <div className={styles.line}></div>
      </div>
      {cart?.map((item, i) => {
        const img = item?.product?.thumbnail || item?.product?.img
        const title = item?.product?.name || item?.product?.title
        const description = item?.product?.description || item?.product?.text

        return (
          <div key={i + title}>
            <div className={styles.grid}>
              <div className={styles.firstColumn}>
                <div className={styles.imageBox}>
                  <Image src={img} alt="img" />
                </div>
                <div className={styles.itemText}>
                  <h4>{title}</h4>
                  <div className={styles.description}>
                    <span>{description}</span>
                  </div>
                  {/*<div className={styles.mobileSize}>XXL</div>*/}
                  <div className={styles.delete} onClick={() => onDelete(item)}>
                    <i>
                      <IoClose />
                    </i>
                    <span>Удалить</span>
                  </div>
                </div>
              </div>

              {/*<div className={styles.secondColumn}>XXL</div>*/}
              <div className={styles.thirdColumn}>
                <QuantityBox item={item} quantity={item?.unit_quantity} setQuantity={setQuantity} />
              </div>
              <div className={styles.fourthColumn}>
                <strong className={styles.price}>{item?.product?.price} ₸</strong>
              </div>
            </div>

            <div className={styles.mobileFlex}>
              <strong className={styles.price}>{item?.product?.price} ₸</strong>
              <QuantityBox item={item} quantity={item?.unit_quantity} setQuantity={setQuantity} />
            </div>
          </div>
        )
      })}
    </div>
  );
}
