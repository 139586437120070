import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { cartReducer } from "./cartReducer";
import { favoriteReducer } from "./favoriteReducer";
import { servicesReducer } from "./servicesReducer";
import { salesReducer } from "./salesReducer";
import { modalReducer } from "./modalReducer";
import { promotionsReducer } from "./promotionsReducer";

export default combineReducers({
  auth: authReducer,
  cart: cartReducer,
  favorite: favoriteReducer,
  services: servicesReducer,
  sales: salesReducer,
  modal: modalReducer,
  promotions: promotionsReducer,
});
