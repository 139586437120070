import styles from "./quantity.module.css";

export default function QuantityBox({ item, quantity, setQuantity }) {
  const onMinusClick = () => {
    if (quantity <= 0) {
      return null
    }

    if (item) {
      return setQuantity(item, false)
    }

    return setQuantity((e) => e - 1)
  }

  const onPlusClick = () => {
    if (item) {
      return setQuantity(item, true)
    }

    return setQuantity((e) => e + 1)
  }

  return (
    <div className={styles.quantityBox}>
      <div
        className={
          quantity <= 0
            ? styles.minusCircle + " " + styles.inactive
            : styles.minusCircle
        }
        onClick={onMinusClick}
      ></div>
      <input
        type="text"
        value={quantity}
        className={styles.numberBox}
        onChange={(e) => setQuantity(item, null, e.target.value)}
      />
      <div
        className={styles.minusCircle + " " + styles.plus}
        onClick={onPlusClick}
      ></div>
    </div>
  );
}
