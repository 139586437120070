import { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/ru";
// import { BsStarHalf, BsFillStarFill } from "react-icons/bs";
import { BsFillStarFill } from "react-icons/bs";
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import CarouselTopArrows from "../../components/CarouselTopArrows/CarouselTopArrows";
import Block from "../../components/Block/Block";
import feed from "../../assets/icons/feed.svg";
import dogCat from "../../assets/images/cat-dog.jpg";
import { useDispatch, useSelector } from "react-redux";
import QuantityBox from "../../components/QuantityBox";
import { addToCart, deleteFromCart } from "../../actions/cartAction";
import {
  addToFavorite,
  deleteFromFavorite,
} from "../../actions/favoritesAction";
import { fetchExactItem, fetchReviews, postReview } from "../../api";

import styles from "./ItemPage.module.css";
import {setBearerToken} from "../../utils/req";
import { useCookies } from "react-cookie";
import Image from "../../utils/Image";

const SIZES = ["XS", "S", "M", "L", "XL", "XXL"];

const text =
  "Сухой корм с говядиной для собак различных пород на всех стадиях жизни";

const colorArr = [
  " blueCircle",
  " accentBlueCircle",
  " pinkCircle",
  " orangeCircle",
  " yellowCircle",
  " greenCircle",
  " purpleCircle",
];

export default function ItemPage({ id, type = "Category name" }) {
  const favoriteProducts = useSelector((state) => state.favorite);
  const cartProducts = useSelector((state) => state.cart);
  const [reviewForm, setReviewForm] = useState({ rating: 3 });
  const [productForm, setProductForm] = useState({});
  const [reviews, setReviews] = useState([]);
  const [ratingBeforeHover, setRatingBeforeHover] = useState();
  const [product, setProduct] = useState();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [imageIndex, setImageIndex] = useState(0);
  const [cookies, setCookie] = useCookies(["user"]);
  const config = setBearerToken(cookies);

  moment.locale('ru')

  useEffect(() => {
    fetchExactItem(id).then((val) => {
      console.log("val = ", val);
      if (val.success) setProduct(val.data);
    });

    fetchReviews(id).then((val) => {
      if (val.success) setReviews(val.data.reviews);
    });
  }, [id]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setReviewForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleProductForm = (formName, formValue) => {
    let name = formName;
    let value = formValue;

    setProductForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const isDisabled = () => {
    return !reviewForm?.review
  }

  const productInCart = () => {
    return cartProducts?.find((cartProduct) => cartProduct?.product?.id === product?.id)
  }

  const setProductQuantity = (item, isAdding) => {
    if (productInCart()) {
      const itemQuantity = isAdding ? item.unit_quantity + 1 : item.unit_quantity - 1

      return dispatch(addToCart(item.product, itemQuantity, config))
    }
  }

  const onSubmitForm = (e) => {
    e.preventDefault();

    if (isDisabled()) {
      return
    }

    const config = setBearerToken(cookies);

    postReview(id, reviewForm, config).then(() => {
      fetchReviews(id).then((val) => {
        if (val.success) setReviews(val.data.reviews);
      });
    });
  };

  const productToFavorite = () => {
    if (!product) {
      return
    }

    return {
      ...product,
      img: product.thumbnail,
      title: product.name,
      text: product.description,
      itemType: product.category.name,
    }
  }

  const productInFavorites = () => {
    return favoriteProducts?.find((favoriteProduct) => {
      return favoriteProduct?.id === product?.id
    })
  }

  const buttonClass = () => {
    if (isDisabled()) {
      return 'grey-btn '
    }

    return 'green-btn '
  }

  const renderReviews = () => {
    if (!reviews.length) {
      return null
    }

    return reviews.map((review) => {
      const time = moment(review.created_at).fromNow()

      return (
        <div className={styles.flexible}>
          <div className={styles.reviewNames}>
            <div className={styles.stars}>
              {Array.from({ length: 5 }, (_, i) => (
                <BsFillStarFill
                  key={i}
                  className={review?.rating > i && styles.yellowStar}
                />
              ))}
            </div>

            <strong className={styles.strong}>{review.user}</strong>
            <p className={styles.small}>{time}</p>
          </div>

          <div className={styles.reviewIcons}>
            <div className={styles.reviewStatus}>{review.review}</div>
            <div>
                <span className={styles.icon}>
                  <i>
                    <MdFavoriteBorder />
                  </i>
                </span>
              <span className={styles.iconText}>113</span>

              <span className={styles.icon}>
                  <span className={styles.circle}></span>
                </span>
              <span className={styles.iconText}>6</span>
            </div>
          </div>
        </div>
      )
    })
  }

  const renderImages = () => {
    return (
      <div className={styles.otherImages}>
        {product?.images.map((imageUrl, i) => {
          return (
            <div
              className={styles.btmImg}
              onClick={() => setImageIndex(i)}
            >
              <Image src={imageUrl} />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Все категории</span>
          <span>{type}</span>
        </div>

        <div className={styles.flex}>
          <div>
            <div className={styles.mainimg}>
              <Image src={product?.images[imageIndex]} />
            </div>
            {renderImages()}
          </div>

          <div className={styles.itemDescription}>
            <div className={styles.stars}>
              {Array.from({ length: 5 }, (_, i) => (
                <BsFillStarFill
                  key={i}
                  className={product?.rating > i && styles.yellowStar}
                />
              ))}
            </div>

            <h1 className={styles.name}>{product?.name}</h1>
            <h2 className={styles.price}>
              {product?.price + " ₸"}
            </h2>

            <div className={styles.mobileImg}>
              <Image src={product?.images[imageIndex]} />
            </div>
            <p>{product?.description}</p>
            {/*<p className={styles.header}>Цвет</p>*/}
            {/*<div className="flex">*/}
              {/*{colorArr.map((color, i) => (*/}
                {/*<div*/}
                  {/*className={*/}
                    {/*productForm?.color === i*/}
                      {/*? styles.circle + color + " " + styles.active*/}
                      {/*: styles.circle + color*/}
                  {/*}*/}
                  {/*key={color}*/}
                  {/*onClick={() => {*/}
                    {/*handleProductForm("color", i);*/}
                  {/*}}*/}
                {/*></div>*/}
              {/*))}*/}
            {/*</div>*/}

            {/*<p className={styles.header}>Размер</p>*/}

            {/*<div className="flex">*/}
              {/*{SIZES.map((item, i) => (*/}
                {/*<div*/}
                  {/*key={i}*/}
                  {/*className={*/}
                    {/*productForm?.size === i*/}
                      {/*? styles.box + " " + styles.active*/}
                      {/*: styles.box*/}
                  {/*}*/}
                  {/*onClick={() => {*/}
                    {/*handleProductForm("size", i);*/}
                  {/*}}*/}
                {/*>*/}
                  {/*{item}*/}
                {/*</div>*/}
              {/*))}*/}
            {/*</div>*/}

            {/*<p className={styles.header}>Пол</p>*/}
            {/*<div className="flex">*/}
              {/*<div*/}
                {/*className={*/}
                  {/*productForm?.sex === 0*/}
                    {/*? styles.box + " " + styles.active*/}
                    {/*: styles.box*/}
                {/*}*/}
                {/*onClick={() => {*/}
                  {/*handleProductForm("sex", 0);*/}
                {/*}}*/}
              {/*>*/}
                {/*Мужской*/}
              {/*</div>*/}

              {/*<div*/}
                {/*className={*/}
                  {/*productForm?.sex === 1*/}
                    {/*? styles.box + " " + styles.active*/}
                    {/*: styles.box*/}
                {/*}*/}
                {/*onClick={() => {*/}
                  {/*handleProductForm("sex", 1);*/}
                {/*}}*/}
              {/*>*/}
                {/*Женский*/}
              {/*</div>*/}
            {/*</div>*/}

            <p className={styles.header}>Количество</p>
            <QuantityBox item={productInCart()} quantity={productInCart()?.unit_quantity || quantity} setQuantity={productInCart() ? setProductQuantity : setQuantity} />

            <div className="flex-center">
              <button
                className={"green-btn " + styles.paddingBtn}
                onClick={(e) => {
                  e.preventDefault();
                  if (productInCart()) {
                    dispatch(deleteFromCart(product, config));
                    setQuantity(1)
                  } else {
                    dispatch(addToCart(product, quantity, config));
                  }
                }}
              >
                <svg
                  viewBox="0 0 33 33"
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles.cart}
                >
                  <path
                    d="M5.39075 30.8285L6.41671 30.3155L6.41671 30.3155L5.39075 30.8285ZM27.021 30.8285L28.047 31.3415L28.047 31.3415L27.021 30.8285ZM1.65273 12.9235H30.759V10.6294H1.65273V12.9235ZM30.0471 12.2115V13.151H32.3412V12.2115H30.0471ZM26.6675 29.9H5.7443V32.1941H26.6675V29.9ZM2.36471 13.151V12.2115H0.0705925V13.151H2.36471ZM6.41671 30.3155C3.752 24.9861 2.36471 19.1095 2.36471 13.151H0.0705925C0.0705925 19.4656 1.5408 25.6935 4.36479 31.3415L6.41671 30.3155ZM5.7443 29.9C6.02906 29.9 6.28937 30.0609 6.41671 30.3155L4.36479 31.3415C4.62605 31.864 5.1601 32.1941 5.7443 32.1941V29.9ZM25.9951 30.3155C26.1224 30.0609 26.3827 29.9 26.6675 29.9V32.1941C27.2517 32.1941 27.7857 31.864 28.047 31.3415L25.9951 30.3155ZM30.0471 13.151C30.0471 19.1095 28.6598 24.9861 25.9951 30.3155L28.047 31.3415C30.871 25.6935 32.3412 19.4656 32.3412 13.151H30.0471ZM30.759 12.9235C30.3658 12.9235 30.0471 12.6047 30.0471 12.2115H32.3412C32.3412 11.3377 31.6328 10.6294 30.759 10.6294V12.9235ZM1.65273 10.6294C0.778938 10.6294 0.0705925 11.3377 0.0705925 12.2115H2.36471C2.36471 12.6047 2.04595 12.9235 1.65273 12.9235V10.6294ZM8.62478 12.3666L15.0483 1.66071L13.0811 0.4804L6.65759 11.1863L8.62478 12.3666ZM17.3635 1.66071L23.787 12.3666L25.7542 11.1863L19.3307 0.4804L17.3635 1.66071Z"
                    fill="#FFF"
                  />
                </svg>
                {productInCart() ? "Убрать с корзины" : "В корзину"}
              </button>

              <div
                className={styles.textBtn}
                onClick={(e) => {
                  e.preventDefault();

                  if (productInFavorites()) {
                    dispatch(deleteFromFavorite(productToFavorite()))
                  } else {
                    dispatch(addToFavorite(productToFavorite()))
                  }
                }}
              >
                <i>
                  {productInFavorites() ? (
                    <MdFavorite />
                  ) : (
                    <MdFavoriteBorder />
                  )}
                </i>
                <span>Избранное</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.line}></div>

        <Block
          headerText="Описание товара"
          text1={product?.description}
          img={dogCat}
        />

        <div className={styles.reviewBox}>
          <h2 className={styles.headerText}>Отзывы</h2>

          {renderReviews()}

          <div className={styles.line}></div>

          <h2 className={styles.headerText}>Добавить отзыв</h2>

          <form onSubmit={onSubmitForm}>
            <div className={styles.row}>
              <strong className={styles.strong}>Введите имя</strong>
              <input
                name="name"
                type="text"
                className={styles.textField}
                onChange={handleChange}
              />
            </div>

            <div className={styles.row}>
              <strong className={styles.strong}>Ваш отзыв</strong>
              <div className={styles.textArea}>
                <textarea
                  name="review"
                  type="text"
                  className={styles.textField}
                  onChange={handleChange}
                  rows="8"
                />

                <p className={styles.small}>
                  Ваш отзыв должен содержать не менее 50 символов.
                </p>
              </div>
            </div>

            <div className={styles.row}>
              <strong className={styles.strong}>Оценка</strong>

              <div className={styles.stars}>
                {Array.from({ length: 5 }, (_, i) => (
                  <BsFillStarFill
                    key={i}
                    className={reviewForm.rating > i && styles.yellowStar}
                    onClick={() => {
                      setRatingBeforeHover(i + 1);
                      setReviewForm((prev) => {
                        return { ...prev, rating: i + 1 };
                      });
                    }}
                    onMouseOver={() => {
                      setRatingBeforeHover(reviewForm.rating);
                      setReviewForm((prev) => {
                        return { ...prev, rating: i + 1 };
                      });
                    }}
                    onMouseOut={() => {
                      setReviewForm((prev) => {
                        return { ...prev, rating: ratingBeforeHover };
                      });
                    }}
                  />
                ))}
              </div>
            </div>

            <button className={buttonClass() + styles.margin} type="submit">
              Отправить
            </button>
          </form>
        </div>

        <CarouselTopArrows
          headerText="Популярные товары"
          linkText="Смотреть все"
          link={"/"}
          img={feed}
          text={text}
        />
      </div>
    </div>
  );
}
