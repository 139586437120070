import React, { useState, useRef } from "react";
import Slider from "react-slick";

import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import styles from "./Carousel.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SamplePrevArrow(props) {
  const { onClick, arrowTop, onPrev, sideArrows, firstSlide } = props;

  return (
    <React.Fragment>
      <div
        className={
          sideArrows
            ? styles.sidePrevArrow + " " + styles.btn
            : arrowTop
            ? styles.prevBtn2 + " " + styles.btn
            : styles.prevBtn1 + " " + styles.btn
        }
        style={{
          color: firstSlide ? "#ccc" : "black",
        }}
        onClick={() => {
          if (onClick) onClick();
          if (onPrev) onPrev();
        }}
      >
        <MdKeyboardArrowLeft />
      </div>
      {!sideArrows && <div className={styles.line}></div>}
    </React.Fragment>
  );
}

function SampleNextArrow(props) {
  const { onClick, arrowTop, onNext, sideArrows, lastSlide } = props;

  return (
    <div
      className={
        sideArrows
          ? styles.sideNextArrow + " " + styles.btn
          : arrowTop
          ? styles.nextBtn2 + " " + styles.btn
          : styles.nextBtn1 + " " + styles.btn
      }
      style={{
        color: lastSlide ? "#ccc" : "black",
      }}
      onClick={() => {
        if (onClick) onClick();
        if (onNext) onNext();
      }}
    >
      <MdKeyboardArrowRight />
    </div>
  );
}

const MobileCarousel = ({
  length,
  children,
  slidesToShow = 1,
  infinite = true,
  onBannerChange,
}) => {
  const [index, setIndex] = useState(0);
  const [firstSlide, setFirstSlide] = useState(true);
  const [lastSlide, setLastSlide] = useState(false);
  const sliderRef = useRef(null);

  const onNext = () =>
    setIndex((i) => {
      if (length - 1 === i) {
        if (onBannerChange) onBannerChange(0);
        return 0;
      }

      if (onBannerChange) onBannerChange(i + 1);
      return i + 1;
    });

  const onPrev = () =>
    setIndex((i) => {
      if (i === 0) {
        if (onBannerChange) onBannerChange(length - 1);
        return length - 1;
      }
      if (onBannerChange) onBannerChange(i - 1);

      return i - 1;
    });

  const settings = {
    arrows: false,
    dots: false,
    infinite: infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow onNext={onNext} lastSlide={lastSlide} />,
    prevArrow: <SamplePrevArrow onPrev={onPrev} firstSlide={firstSlide} />,
    beforeChange: (_, next) => {
      if (onBannerChange) onBannerChange(next);
      if (next === 0 && !firstSlide) setFirstSlide(true);
      else if (firstSlide) setFirstSlide(false);
      if (next === slidesToShow && !lastSlide) setLastSlide(true);
      else if (lastSlide) setLastSlide(false);
    },
    afterChange: (i) => {
      setIndex(i);
    },
  };

  return (
    <div className={styles.slider}>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default MobileCarousel;
