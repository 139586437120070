import { useState } from "react";
import { TABS, TAB_VIEWS } from "./data";
import styles from "../account/Personal.module.css";

export default function FAQ() {
  const [tabNum, setTabNum] = useState(0);

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>FAQ</span>
        </div>
        <h1 className="head1">FAQ</h1>

        <div className={styles.flex}>
          <div className={styles.tabBox}>
            {TABS.map((tab, i) => (
              <div
                key={i}
                className={
                  tabNum === i ? styles.tab + " " + styles.active : styles.tab
                }
                onClick={() => {
                  setTabNum(i);
                }}
              >
                {tab}
                {i === 5 && <span className={styles.circleText}>6</span>}
              </div>
            ))}
          </div>
          <div className={styles.tabViewBox}>
            <div className={styles.borderCard}>
              <h4>{TAB_VIEWS.header}</h4>
              <p>{TAB_VIEWS.body}</p>
              <p>{TAB_VIEWS.body}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
