import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";

import cat from "../../assets/images/cat.jpg";
import dogImg2 from "../../assets/images/dogImg.jpg";
import { AiOutlineMail } from "react-icons/ai";
import { subscribe } from "../../api";
import { openSuccessPosted } from '../../actions/modalAction'

import styles from "./promotions.module.css";
import Image from "../../utils/Image";
import React from "react";

const bigText =
  "По своей сути рыбатекст является альтернативой традиционному lorem ipsum, который вызывает у некторых людей недоумение при попытках прочитать рыбу текст. В отличии от lorem ipsum, текст рыба на русском языке наполнит любой макет непонятным смыслом";

export default function PromotionsPage() {
  const promotions = useSelector((state) => state.promotions)
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const promotion = () => {
    if (promotions.length) {
      return promotions.find((promotion) => {
        return promotion.type === "Акция"
      })
    }

    return {}
  }

  const sale = () => {
    if (promotions.length) {
      return promotions.find((promotion) => {
        return promotion.type === "Распродажа"
      })
    }

    return {}
  }

  const renderFirstPromotion = () => {
    const { headline, img, description, product } = promotion()

    return (
      <div className={styles.card1}>
        <div className={styles.sale + " " + styles.blue}>
          Акция
        </div>

        <div className={styles.boxImg}>
          <Image src={img} />
        </div>
        <div className={styles.textBox}>
          <h3>{headline}</h3>
          <p>{description}</p>

          <Link to={`/${product?.category}/${product?.id}`}>
            <button className="green-btn">Купить</button>
          </Link>
        </div>
      </div>
    )
  }

  const renderSale = () => {
    const { headline, img, description, product } = sale()

    return (
      <div className={styles.card2}>
        <div className={styles.boxImg}>
          <Image src={img} />
        </div>

        <div className={styles.text}>
          <h2>{headline}</h2>
          <p>{description}</p>
          <Link to={`/${product?.category}/${product?.id}`}>
            <button className="green-btn">Купить</button>
          </Link>
        </div>

        <div className={styles.sale}>Распродажа</div>
      </div>
    )
  }


  const onSubscribe = () => {
    subscribe({ email })
      .then((response) => {
        dispatch(openSuccessPosted())
      })
      .catch(() => {
        setErrMsg('Вы ввели данные некорректно...')
      });
  }

  return (
    <div>
      <div className="body-page">
        <div className="contain">
          <div className="flex link">
            <span>Главная</span>
            <span>Акции</span>
          </div>
          <h1 className="head1">Акции</h1>
          {renderFirstPromotion()}
          <div className={styles.grid}>
            <div className={styles.catBox}>
              <img src={cat} alt="cat" />
              <div className={styles.textOnBox}>
                <h2>МЯУ</h2>
                <p className={styles.textBtn}>Смотрите ЧТО!</p>
              </div>
            </div>

            <div className={styles.dogBox}>
              <img src={dogImg2} alt="dog" />
              <div className={styles.textOnBox}>
                <h2>Собаки</h2>
              </div>
            </div>
            {renderSale()}
          </div>
        </div>
      </div>

      <div className={styles.discountBanner}>
        <h4 className={styles.headerText}>10% скидка на товары магазина</h4>
        <p className={styles.bigText}>{bigText}</p>

        <div className={styles.emailBox}>
          <div className={styles.inputBox}>
            <i>
              <AiOutlineMail />
            </i>
            <input
              type="text"
              placeholder="Ваш e-mail"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            className="green-btn"
            onClick={onSubscribe}
          >
            Подписаться
          </button>
        </div>
        {errMsg && <div className={styles.errMsg}>{errMsg}</div>}
      </div>
    </div>
  );
}
