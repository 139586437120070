import { useEffect, useState } from "react";

import PersonalData from "./sections/PersonalData/PersonalData";

import feed from "../../assets/icons/feed.svg";
import Reviews from "./sections/Reviews/Reviews";
import Memo from "./sections/Memo/Memo";
import { PROFILE_TABS } from "../../utils/const/home";
import OrdersPage from "./sections/Orders";
import Bonuses from "./sections/Bonuses";
import Notifications from "./sections/Notifications";
import PetData from "./sections/PetData/PetData";
import * as api from "../../api";
import { useCookies } from "react-cookie";
import { setBearerToken } from "../../utils/req";

import styles from "./Personal.module.css";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../actions/auth";

export default function PersonalArea() {
  const [tabNum, setTabNum] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [pets, setPets] = useState([]);
  const [orders, setOrders] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [bonuses, setBonuses] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [notLogged, setNotLogged] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let config = setBearerToken(cookies);

    if (config === null) setNotLogged(true);
    else {
      api.fetchUserNotifications(config).then((val) => {
        if (val.success) setNotifications(val.data);
      });

      // api.fetchPets(config).then((val) => {
      //   if (val.success) setPets(val.data);
      // });

      api.fetchUserReviews(config).then((val) => {
        if (val.data) setReviews(val.data)
      })

      api.getOrders(config).then((val) => {
        if (val.data) setOrders(val.data)
      })

      api.fetchUserBonuses(config).then((val) => {
        if (val.bonuses) setBonuses(val.bonuses)
      })

      api.fetchOrderStatuses(config).then((val) => {
        if (val.data) setStatuses(val.data)
      })
    }
  }, []);

  const notReadNotifications = () => {
    return notifications.filter((notification) => {
      return !notification.read
    })
  }
  const readNotification = (id) => {
    let config = setBearerToken(cookies);

    api.fetchExactNotification(id, config).then((response) => {
      if (response.success) {
        api.fetchUserNotifications(config).then((val) => {
          if (val.success) setNotifications(val.data);
        });
      }
    })
  }

  if (notLogged) return <div>Вы не авторизованы</div>;

  const logout = () => {
    removeCookie("session_id");
    dispatch(logoutAction());
  };

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Личный кабинет</span>
        </div>
        <h1 className="head1">Личный кабинет</h1>

        <div className={styles.flex}>
          <div className={styles.tabBox}>
            {PROFILE_TABS.map((tab, i) => (
              <div
                key={i}
                className={
                  tabNum === i ? styles.tab + " " + styles.active : styles.tab
                }
                onClick={() => {
                  setTabNum(i);
                }}
              >
                {tab}
                {i === 5 && (
                  <span className={styles.circleText}>
                    {notReadNotifications().length}
                  </span>
                )}
              </div>
            ))}
            <button className={styles.logoutBtn} onClick={logout}>
              Выйти
            </button>
          </div>

          <div className={styles.tabViewBox}>
            {tabNum === 0 && <PersonalData />}
            {tabNum === 1 && <OrdersPage orders={orders} statuses={statuses} />}
            {tabNum === 2 && <Reviews reviews={reviews} />}
            {tabNum === 3 && <Memo />}
            {tabNum === 4 && <Bonuses bonuses={bonuses} orders={orders} />}
            {tabNum === 5 && <Notifications notifications={notifications} read={readNotification} />}
            {/* {tabNum === 6 && <PetData pets={pets} />} */}
          </div>
        </div>
      </div>
    </div>
  );
}
