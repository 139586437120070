import React, { useEffect, useState } from "react";

import nike from "../../assets/icons/nike.svg";

import styles from "./about.module.css";
import { getAboutPage } from "../../api";
import Image from "../../utils/Image";

export default function AboutPage() {
  const [page, setPage] = useState({})

  useEffect(() => {
    getAboutPage().then((response) => {
      setPage(response.data)
    })
  }, [])

  const renderList = () => {
    if (!page.why_us || !page.why_us.length) {
      return null
    }
    return (
      <React.Fragment>
        <div className={styles.point}>
          <span className={styles.sphere}>1</span>
          <span className={styles.text}>
            {page.why_us[0].description}
                </span>
        </div>

        <div className={styles.point + " " + styles.border}>
          <span className={styles.sphere + " " + styles.blue}>2</span>
          <span className={styles.text}>
                {page.why_us[1].description}
                </span>
        </div>

        <div className={styles.point}>
          <span className={styles.sphere + " " + styles.pink}>3</span>

          <span className={styles.text}>
               {page.why_us[2].description}
                </span>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>О компании</span>
        </div>
        <h1 className="head1">О компании</h1>
        <Image src={page.background_img} className={styles.bigCat} />
        <div className={styles.speechGen}>
          <h2>{page.headline}</h2>
          <p>{page.description}</p>
        </div>
      </div>

      <div className={styles.companiesBanner}>
        <div className="flex-space-btw-center contain">
          <div className={styles.banner}>
            <img src={nike} alt="nike" />
          </div>

          <div className={styles.banner}>
            <img src={nike} alt="nike" />
          </div>

          <div className={styles.banner}>
            <img src={nike} alt="nike" />
          </div>

          <div className={styles.banner}>
            <img src={nike} alt="nike" />
          </div>
        </div>
      </div>

      <div className="contain">
        <div className={styles.advantages}>
          <div className={styles.flex}>
            <div className={styles.imgBox}>
              <Image src={page.second_background_img} />
            </div>
            <div className={styles.rightText}>
              <h2 className={styles.bigText}>{page.second_headline}</h2>
              <p>{page.second_description}</p>
              {renderList()}
            </div>
          </div>

          <div className={styles.mainAdvantages}>
            <div className={styles.leftText}>
              <h2 className={styles.bigText}>{page.third_headline}</h2>
              <p>{page.third_description}</p>
            </div>
            <div className={styles.colors}>
              <Image src={page.third_background_img} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
