export const emailValidator = (email) => {
  const emailValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailValidation.test(String(email).toLowerCase());
};

export const phoneValidator = (phone) => {
  const phoneValidation = /^\+?77([0124567][0-8]\d{7})$/;
  return phoneValidation.test(String(phone));
};
