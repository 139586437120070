import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { MdFavoriteBorder } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import { HiOutlinePhone } from "react-icons/hi";
import { RiArrowRightSLine } from "react-icons/ri";

import cart from "../../assets/icons/cart.svg";
import logo from "../../assets/icons/logo.svg";
import MobileNavbar from "./mobile/mobile_navbar";
import {
  LEFT_NAVBAR,
  PHONES,
  RIGHT_NAVBAR,
  TOP_NAVBAR,
} from "../../utils/const/home";
import { useSelector, useDispatch } from "react-redux";
import { searchItem } from "../../api";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import { openCallback, openLoginBox } from '../../actions/modalAction'

import styles from "./nav.module.css";

const animatedText = "Поиск анимированный";
const speed = 220;
let i = 0;

export default function Navbar({
  loggedIn,
  categories,
}) {
  const dispatch = useDispatch()
  const cartProducts = useSelector((state) => state.cart);
  const authData = useSelector((state) => state.auth.data);
  const favoriteProducts = useSelector((state) => state.favorite);
  const [searchText, setSearchText] = useState("");
  const [path, setPath] = useState("/");
  const [openCatalog, setOpenCatalog] = useState(false);
  const inputRef = useRef();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname)
  }, [location])

  useEffect(() => {
    type();
  }, [searchText]);

  const onSearchBox = (query) => {
    searchItem(query).then((val) => {
      console.log("val = ", val);
    });

    history.push({ pathname: "/catalog", search: `?search_query=${query}` });
  };

  function type() {
    if (inputRef?.current?.value.length > 0) {
      inputRef.current.placeholder = "";
      i = 0;
      return;
    }

    if (i === animatedText.length) reverseType();
    else if (inputRef?.current) {
      inputRef.current.placeholder += animatedText.charAt(i);

      i++;
    }

    setTimeout(type, speed);
  }

  function reverseType() {
    if (inputRef?.current.placeholder.length > 0) {
      let placeholder = inputRef.current.placeholder;
      inputRef.current.placeholder = placeholder.substring(
        0,
        placeholder.length - 1
      );
    } else i = 0;
  }

  const styleActive = (link) => {
    return path === link ? styles.active : ''
  }

  return (
    <div>
      <div className={styles.desktopNavbar}>
        <div className={styles.redirectLinks}>
          <div className="contain flex-space-btw-center">
            <div className={styles.left}>
              {TOP_NAVBAR.map((link, i) => (
                <NavLink to={link.to} key={i}>
                  <p>{link.name}</p>
                </NavLink>
              ))}
            </div>
            <div className={styles.right}>
              <p>Info@zhivoimir.kz</p>
            </div>
          </div>
        </div>

        <div className={styles.secondRow}>
          <div className="flex-space-btw-center contain">
            <div className={styles.flex}>
              <HiOutlinePhone className={styles.phoneIcon} />

              <div className={styles.left}>
                {PHONES.map((phone, i) => (
                  <p key={i}>{phone}</p>
                ))}
                <span
                  className={styles.link}
                  onClick={() => dispatch(openCallback())}
                >
                  Заказать звонок
                </span>
              </div>
            </div>

            <div className={styles.right}>
              <div className={styles.searchBox}>
                <input
                  type="text"
                  key="searchInput"
                  ref={inputRef}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchText(e.target.value);
                  }}
                />
                <i onClick={() => onSearchBox(searchText)}>
                  <BiSearch />
                </i>
              </div>

              <div className={styles.flexBox}>
                <NavLink to="/favorites">
                  <div className={styles.icon}>
                    <MdFavoriteBorder />
                    {favoriteProducts.length > 0 && (
                      <div className={styles.number}>
                        {favoriteProducts.length}
                      </div>
                    )}
                  </div>
                </NavLink>

                <div className={styles.icon}>
                  <NavLink to="/cart">
                    <img src={cart} alt="basket" className={styles.svgIcon} />
                    {cartProducts.length > 0 && (
                      <div className={styles.number}>{cartProducts.length}</div>
                    )}
                  </NavLink>
                </div>

                {loggedIn ? (
                  <NavLink to="/account">
                    <div className={styles.signInIcon}>
                      <BsPerson />
                      <span style={{ marginLeft: "0.2rem" }}>
                        {authData?.name}
                      </span>
                    </div>
                  </NavLink>
                ) : (
                  <div
                    className={styles.signInBox}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(openLoginBox(true))
                    }}
                  >
                    <BsPerson />
                    <span>Вход</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.navBarBox}>
          <div className={styles.navBar}>
            <div
              className={
                openCatalog
                  ? styles.catalog + " " + styles.active
                  : styles.catalog
              }
              onClick={() => setOpenCatalog((e) => !e)}
            >
              <i>
                <AiOutlineMenu />
              </i>
              <span>Каталог</span>

              <div className={styles.dropdownContent}>
                {categories?.map((item, i) => (
                  <div
                    key={i}
                    className={styles.subCatalog}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/catalog?category_id=${item.id}`);
                    }}
                  >
                    <p>
                      {item.name}
                      <i>
                        <RiArrowRightSLine />
                      </i>
                    </p>

                    <div className={styles.subCatalogDropdown}>
                      {item?.children?.map((subItem) => (
                        <div
                          key={subItem.name}
                          className={styles.subCatalogFlexWrap}
                        >
                          <strong
                            className={styles.parentSubcategory}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              history.push(
                                `/catalog?category_id=${item.id},${subItem.id}`
                              );

                              setOpenCatalog(false);
                            }}
                          >
                            {subItem.name}
                          </strong>
                          {subItem?.children?.map((subChildren) => (
                            <span
                              key={subChildren.name}
                              className={styles.subCatalogChildren}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(
                                  `/catalog?category_id=${item.id},${subItem.id},${subChildren.id}`
                                );

                                setOpenCatalog(false);
                              }}
                            >
                              {subChildren.name}
                            </span>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {LEFT_NAVBAR.map((leftNav, i) => (
              <NavLink key={i} to={leftNav.to}>
                <div
                  className={`${styles.navLink} ${styles.leftBorder} ${styleActive(leftNav.to)}`}
                  onClick={() => setPath(leftNav.to)}
                >
                  {leftNav.name}
                </div>
              </NavLink>
            ))}

            <NavLink to="/">
              <div className={styles.logo}>
                <img src={logo} alt="logo" />
              </div>
            </NavLink>

            <div className={styles.rightNavBar}>
              {RIGHT_NAVBAR.map((rightNav, i) => (
                <NavLink key={i} to={rightNav.to}>
                  <div
                    className={`${styles.navLink} ${styles.rightBorder} ${styleActive(rightNav.to)}`}
                    onClick={() => setPath(rightNav.to)}
                  >
                    {rightNav.name}
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mobileNavbar}>
        <MobileNavbar
          openLoginBox={() => {
            dispatch(openLoginBox(true));
          }}
          loggedIn={loggedIn}
          openBoxCall={() => dispatch(openCallback())}
        />
      </div>
    </div>
  );
}
