import React, { useEffect, useState } from "react";
import { fetchNews, fetchExactNews } from "../../../api";
import dogImg from "../../../assets/images/dogImg.jpg";
import { NavLink } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

import styles from "../news.module.css";
import Image from "../../../utils/Image";

export default function ExactNewsPage({ id }) {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const [news, setNews] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchExactNews(id).then((val) => {
      if (val.data) setItem(val.data);
    });

    fetchNews()
      .then((val) => {
        if (val.data) {
          let newNews = val.data.filter((otherNews) => {
            return otherNews.id !== Number.parseInt(id)
          });

          setNews(newNews);
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  // if (loading) return <p className="contain">Loading...</p>;

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>{item?.title}</span>
        </div>
        <h1 className="head1">{item?.title}</h1>

        <Image src={item?.img} className={styles.bigImg} />
        <div className={styles.newsBody}>
          <div className={styles.newsDescription}>
            <strong className={styles.articleTitle}>{item?.title}</strong>
            <p>{item?.description}</p>
          </div>
          <div className={styles.otherArticles}>
            <h4 className={styles.head4}>Другие новости</h4>
            <div className={styles.flexWrap}>
              {news.slice(0, 3).map((otherNews) => (
                <div
                  className={styles.article + " " + styles.nomargin}
                  key={otherNews?.name}
                >
                  <NavLink to={`/news/${otherNews?.id}`}>
                    <div className={styles.title}>
                      <span>{otherNews?.title}</span>
                      <i>
                        <FiArrowUpRight />
                      </i>
                    </div>
                  </NavLink>
                  <p className={item?.description}>{otherNews?.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
