import { BsCheck } from "react-icons/bs";

import styles from "./ServiceCard.module.css";

export default function ServiceCard({ text, pointText, purple = false, price }) {
  return (
    <div
      className={
        purple ? styles.serviceCard + " " + styles.purple : styles.serviceCard
      }
    >
      <div className={styles.header}>
        <h4>Услуга</h4>
        <h4>{price} ₸</h4>
      </div>
      <p>{text}</p>
      <div className={styles.line}></div>

      <div className={styles.points}>
        <div className="flex-center">
          <BsCheck className="green-icon" />
          <span>{pointText}</span>
        </div>

        <div className="flex-center">
          <BsCheck className="green-icon" />
          <span>{pointText}</span>
        </div>

        <div className="flex-center">
          <BsCheck className="green-icon" />
          <span>{pointText}</span>
        </div>

        <div className="flex-center">
          <BsCheck className="green-icon" />
          <span>{pointText}</span>
        </div>

        <div className="flex-center">
          <BsCheck className="green-icon" />
          <span>{pointText}</span>
        </div>
      </div>
    </div>
  );
}
