import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { postRequestOnCallback } from "../../api"
import { closeCallback, openSuccessPosted } from '../../actions/modalAction'

import ModalView from './View'

import { IoClose } from "react-icons/io5"
import InputMask from "react-input-mask"

import styles from "./login.module.css"

export default function CallbackModal() {
  const [formData, setFormData] = useState({});
  const [errorMsg, setErrorMsg] = useState([]);
  const dispatch = useDispatch()
  const { active } = useSelector((state) => state.modal.callback)

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;

    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onSubmitForm = (e) => {
    e.preventDefault()
    setErrorMsg([])

    let newFormData = {
      ...formData,
      phone: "+" + formData?.phone?.replace(/[^\d]/g, ""),
    }

    postRequestOnCallback(newFormData)
      .then(() => {
        dispatch(closeCallback())
        dispatch(openSuccessPosted())
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  const setErrorMessage = (error) => {
    const { data, message } = error.response?.data
    const messages = []

    if (!data && message) {
      messages.push(message)
    } else {
      Object.entries(data).forEach(([key, errors]) => {
        errors.forEach((message) => {
          messages.push(message)
        })
      })
    }

    setErrorMsg(messages)
  }

  const renderErrors = (messages) => {
    if (!messages && !messages.length) {
      return;
    }

    return (
      <React.Fragment>
        {
          messages.map((message) => {
            return <div className={styles.errorMsg}>{message}</div>
          })
        }
      </React.Fragment>
    )
  }

  const renderForm = () => {
    return (
      <div className={styles.paper}>
        <div className={styles.header}>
          Заказать звонок
          <i onClick={() => dispatch(closeCallback())}>
            <IoClose />
          </i>
        </div>
        <div className={styles.body}>
          <div className={styles.textBox}>
            <p className={styles.label}>Имя</p>
            <input
              name="name"
              placeholder="Имя"
              type="text"
              onChange={handleChange}
              className={styles.textField}
            />
          </div>
          <div className={styles.textBox}>
            <p className={styles.label}>Контакты</p>
            <InputMask
              name="phone"
              placeholder="+7 (707) 746 47 94"
              mask="+7 (999) 999-99-99"
              maskChar="_"
              onChange={handleChange}
              className={styles.textField}
            />
          </div>
          {renderErrors(errorMsg)}
          <div className={styles.btnBox}>
            <button
              className={"green-btn" + " " + styles.fullWidth}
              onClick={(e) => {
                onSubmitForm(e)
              }}
            >
              Заказать звонок
            </button>
          </div>
        </div>
      </div>
    )
  }
  return (
    <ModalView
      active={active}
      setActive={() => dispatch(closeCallback())}
      hideBg={true}
    >
      {renderForm()}
    </ModalView>
  )
}