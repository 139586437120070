import React, { useEffect, useState } from "react";
import { fetchArticles, fetchExactArticle } from "../../../api";
import { NavLink } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

import styles from "../news.module.css";
import Image from "../../../utils/Image";

export default function ArticlePage({ id }) {
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchExactArticle(id).then((val) => {
      if (val.success) setArticle(val.data);
    });

    fetchArticles()
      .then((val) => {
        if (val.success) {
          let newArticles = val.data.articles.filter(
            (otherArticle) => otherArticle.id !== Number.parseInt(id)
          );

          setArticles(newArticles);
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  // if (loading) return <p className="contain">Loading...</p>;

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>{article?.title}</span>
        </div>
        <h1 className="head1">{article?.title}</h1>

        <Image src={article?.thumbnail} className={styles.bigImg} />
        <div className={styles.newsBody}>
          <div className={styles.newsDescription}>
            <strong className={styles.articleTitle}>{article?.title}</strong>
            <p>{article?.body}</p>
          </div>
          <div className={styles.otherArticles}>
            <h4 className={styles.head4}>Другие статьи</h4>
            <div className={styles.flexWrap}>
              {articles.slice(0, 3).map((otherArticle) => (
                <div
                  className={styles.article + " " + styles.nomargin}
                  key={otherArticle?.name}
                >
                  <NavLink to={`/articles/${otherArticle.id}`}>
                    <div className={styles.title}>
                      <span>{otherArticle?.title}</span>
                      <i>
                        <FiArrowUpRight />
                      </i>
                    </div>
                  </NavLink>
                  <p className={article?.body}>{otherArticle?.body}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
