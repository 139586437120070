import moment from "moment";
import "moment/locale/ru";
import { BsFillStarFill } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";

import styles from "./Reviews.module.css";
import Image from "../../../../utils/Image";

export default function Reviews({ reviews }) {
  moment.locale('ru')

  const time = (createdAt) => moment(createdAt).fromNow()

  return (
    <div>
      {reviews.map((review, i) => {
        return (
          <div className={styles.reviewBox} key={i}>
            <div className={styles.imgBox}>
              <Image src={review.product_img} alt="feed" />
            </div>
            <div>
              <p className={styles.greenText}>Опубликован</p>
              <h4 className={styles.nameText}>
                {review.product_title}
              </h4>
              <p className={styles.reviewText}>{review.review}</p>
              <div>
                {Array.from({ length: 5 }, (_, i) => (
                  <i key={i} className={review?.rating > i ? styles.star : styles.grayStar}>
                    <AiFillStar />
                  </i>
                ))}
              </div>
              <span className={styles.date}>{time(review.created_at)}</span>
            </div>
          </div>
        )
      })}
    </div>
  );
}
