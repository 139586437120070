import styles from "./s.module.css";
import otherStyles from "../Orders/s.module.css";
import Image from "../../../../utils/Image";

export default function Bonuses({ bonuses, orders }) {
  const renderOrders = () => {
    return orders.map((order) => {
      if (!order.bonuses) {
        return null
      }

      let price = 0

      order?.order_items?.forEach((item) => {
        price += item?.price * item?.unit_quantity
      })

      return (
        <div className={otherStyles.card}>
          <div className={otherStyles.cardText}>
            <h3>Заказ №{order.id}</h3>
            <p className={styles.price}>
              {price} ₸ <span className={styles.bonus}>+{order.bonuses} Б</span>
            </p>
          </div>
          <div className={otherStyles.flexWrap}>
            {order?.order_items?.map((item, i) => {
              return (
                <Image key={i} src={item?.product?.thumbnail} className={otherStyles.feedCard} />
              )
            })}
          </div>
        </div>
      )
    })
  }
  return (
    <div>
      <h2>Бонусы</h2>
      <div className={styles.card + " " + styles.card1}>
        <div className={styles.bonusBox}>
          <span className={styles.p}>Накоплено бонусов:</span>
          <span className={styles.w700}>{bonuses} Б</span>
        </div>
      </div>

      <div className={styles.card + " " + styles.card2}>
        <h3>Покупки с бонусами:</h3>
        {renderOrders()}
        {/*<div className={styles.feedCard}>*/}
          {/*<img src={feed} alt="feed" />*/}
          {/*<div>*/}
            {/*<p className={styles.w600}>{name}</p>*/}
            {/*<p className={styles.w400}>{description}</p>*/}
            {/*<p className={styles.w400}>Количество: {quantity}</p>*/}
            {/*<p className={styles.price}>*/}
              {/*{price} ₸ <span className={styles.bonus}>+{bonus} Б</span>*/}
            {/*</p>*/}
          {/*</div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
