import { useEffect, useState } from "react";
import cashIcon from "../../assets/icons/cashIcon.svg";
import styles from "./delivery.module.css";

import { getDeliveryPage, fetchPaymentTypes } from "../../api";
import Image from "../../utils/Image";

export default function DeliveryPage() {
  const [page, setPage] = useState([])
  const [payments, setPayments] = useState([])

  useEffect(() => {
    getDeliveryPage().then((response) => setPage(response.data))
    fetchPaymentTypes().then((response) => setPayments(response.data))
  }, [])

  const renderDelivery = () => {
    return page.map(({ description, headline, img}) => {
      return (
        <div className={styles.deliveryCard}>
          <h3 className={styles.head3}>{headline}</h3>
          <p>{description}</p>
          <div className={styles.imgBox}>
            <Image src={img} />
          </div>
        </div>
      )
    })
  }

  const renderPayments = () => {
    return payments.map(({ description, name }) => {
      return (
        <div className={styles.card}>
          <img src={cashIcon} alt="cashIcon" width="80" height="80" />
          <h3 className={styles.head3}>{name}</h3>
          <p>{description}</p>
        </div>
      )
    })
  }

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Доставка</span>
        </div>
        <h1 className="head1">Доставка</h1>

        <div className={"flex-wrap " + styles.marginLeft}>
          {renderDelivery()}
        </div>

        <h2 className={styles.head2}>Способы оплаты</h2>

        <div className={"flex-wrap " + styles.marginLeft}>
          {renderPayments()}
        </div>
      </div>
    </div>
  );
}
