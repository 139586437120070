export const ADD_TO_FAVORITE = "ADD_TO_FAVORITE";
export const DELETE_FROM_FAVORITE = "DELETE_FROM_FAVORITE";
export const UPDATE_CART_FAVORITES = "UPDATE_CART_FAVORITES";
export const FETCH_FAVORITE_PRODUCTS = "FETCH_FAVORITE_PRODUCTS";
export const CLEAR_FAVORITES = "CLEAR_FAVORITES";

export const favoriteReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_TO_FAVORITE:
      let newState = [...state, action.payload];
      localStorage.setItem("favorites", JSON.stringify(newState));

      return newState;
    case DELETE_FROM_FAVORITE:
      let newProducts = state.filter((item) => item.id !== action.payload);
      localStorage.setItem("favorites", JSON.stringify(newProducts));
      return newProducts;
    case FETCH_FAVORITE_PRODUCTS:
      let newState2 = JSON.parse(localStorage.getItem("favorites")) || [];
      return newState2;
    case UPDATE_CART_FAVORITES:
      return state;
    case CLEAR_FAVORITES:
      localStorage.removeItem("favorites");
      return [];
    default:
      return state;
  }
};
