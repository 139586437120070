import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./pages/home/home";
import ItemPage from "./pages/ItemPage/ItemPage";
import Catalog from "./pages/catalog";
import Delivery from "./pages/delivery";
import Contact from "./pages/contact";
import Cart from "./pages/cart";
import About from "./pages/about";
import News from "./pages/news";
import Services from "./pages/services";
import Sale from "./pages/sale";
import Works from "./pages/works";
import Promotions from "./pages/promotions";

import ScrollToTop from "./components/ScrollToTop";
import Discounts from "./pages/discounts/discounts";
import PersonalArea from "./pages/account/PersonalArea";
import ReferenceBooks from "./pages/reference-books";
import PopupModal from "./components/Modal/Popup";
import SuccessPostedModal from "./components/Modal/SuccessPosted";
import ServiceBuyModal from "./components/Modal/ServiceBuy";
import CallbackModal from "./components/Modal/Callback";
import LoginModal from "./components/Modal/Login";
import ChangePasswordModal from "./components/Modal/ChangePassword";

import React, { useEffect, useState } from "react";
import { FETCH_FAVORITE_PRODUCTS } from "./reducers/favoriteReducer";
import FAQ from "./pages/faq/FAQ";
import { useDispatch } from "react-redux";
import ExactNewsPage from "./pages/news/pages/news";
import ArticlePage from "./pages/news/pages/article";
import { useCookies } from "react-cookie";
import { setBearerToken } from "./utils/req";
import { getUserData } from "./actions/auth";
import { useSelector } from "react-redux";
import { fetchCartProducts } from "./actions/cartAction";
import { fetchServices } from "./actions/serviceAction";
import { fetchSales } from "./actions/salesAction";
import { fetchPromotions } from "./actions/promotionsAction";
import { openPopup } from "./actions/modalAction";
import PrivateRoute from "./utils/privateRoute";
import NoveltyPage from "./pages/novelty/Novelty";
import { fetchCategories } from "./api";
import FavoritesPage from "./pages/favorites/Favorites";
import whatsapp from "./assets/icons/whatsapp.svg";
import instagram from "./assets/icons/instagram.svg";

function App() {
  const [cookies, setCookie] = useCookies(["user"]);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    dispatch({ type: FETCH_FAVORITE_PRODUCTS });
    fetchCategories().then((val) => {
      if (val.success) setCategories(val.data);
    });

    let config = setBearerToken(cookies);

    if (config) {
      dispatch(fetchCartProducts(config));
      dispatch(getUserData(config));
    }

    dispatch(fetchServices());
    dispatch(fetchSales());
    dispatch(fetchPromotions());

    if (!config) {
      dispatch(openPopup())
    }

  }, [cookies, dispatch]);

  const onClickWhatsapp = () => {
    window.open("https://wa.me/77714099922?text=Добрый%20день,%20", '_blank');
  };

  const onClickInstagram = () => {
    window.open(" https://instagram.com/zoo_zhivoi_mir", '_blank');
  };

  return (
    <div className="app-body">
      <Router>
        <Navbar
          loggedIn={authData?.data}
          categories={categories}
        />
        <ScrollToTop />
        <Switch>
          <Route
            path="/"
            exact
            component={() => <Home categories={categories} />}
          />
          <Route
            path="/catalog"
            exact
            component={() => <Catalog categories={categories} />}
          />
          <Route path="/contact" exact component={Contact}
          />
          <Route path="/news" exact component={News} />
          <Route path="/about" exact component={About} />
          <Route path="/promotions" exact component={Promotions} />
          <Route path="/cart" exact component={Cart} />
          <Route path="/works" exact component={Works} />
          <Route path="/sale" exact component={Sale} />
          <Route path="/novelty" exact component={NoveltyPage} />
          <Route path="/services" exact component={Services} />
          <Route path="/delivery" exact component={Delivery} />
          <Route path="/favorites" exact component={FavoritesPage} />

          <Route
            path="/news/:id"
            exact
            component={({ match: { params } }) => (
              <ExactNewsPage id={params.id} />
            )}
          />
          <Route
            path="/articles/:id"
            exact
            component={({ match: { params } }) => (
              <ArticlePage id={params.id} />
            )}
          />
          <Route
            path="/:categoryName/:id"
            exact
            component={({ match: { params } }) => (
              <ItemPage id={params.id} type={params.categoryName} />
            )}
          />
          <Route path="/discounts" exact component={Discounts} />
          <PrivateRoute
            path="/account"
            exact
            isAuthenticated={authData.data != null}
          >
            <PersonalArea />
          </PrivateRoute>
          <Route path="/references" exact component={ReferenceBooks} />
          <Route path="/questions" exact component={FAQ} />
        </Switch>
        <Footer categories={categories} />

        <LoginModal />
        <PopupModal />
        <ServiceBuyModal />
        <SuccessPostedModal />
        <CallbackModal />
        <ChangePasswordModal />
      </Router>

      <div className="absoluteImg">
        <div className="pulse">
          <img src={whatsapp} alt="whatsapp" onClick={onClickWhatsapp} />
        </div>
        <div className="pulse pink" style={{ marginBottom: 0 }}>
          <img src={instagram} alt="instagram" onClick={onClickInstagram} />
        </div>
      </div>
    </div>
  );
}

export default App;
