import { NavLink } from "react-router-dom";
import dogImg from "../../assets/images/dogImg.jpg";
import bannerBG from "../../assets/images/bannerBG.jpg";
import { FiArrowUpRight } from "react-icons/fi";

import Pagination from "../../components/Pagination";

import styles from "./news.module.css";
import saleStyles from "../sale/sale.module.css";

import { useEffect, useState } from "react";
import { fetchNews, fetchArticles } from "../../api";
import Image from "../../utils/Image";

const headText = "По своей сути рыбатекст является";
const bigText =
  "По своей сути рыбатекст является альтернативой традиционному lorem ipsum, который вызывает у некторых людей недоумение при попытках прочитать рыбу текст. В отличии от lorem ipsum, текст рыба на русском языке наполнит любой макет непонятным смыслом и придаст неповторимый колорит советских времен.";

export default function NewsPage() {
  const [articles, setArticles] = useState([]);
  const [news, setNews] = useState([]);
  const [newsPage, setNewsPage] = useState(1);
  const [articlesPage, setArticlesPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchNews()
      .then((val) => {
        if (val.data) {
          setNews(val.data);
          setNewsPage(val.data.current_page);
        }
      })
      // .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchArticles()
      .then((val) => {
        if (val.success) {
          setArticles(val.data.articles);
          setArticlesPage(val.data.current_page);
        }
      })
    // .finally(() => setLoading(false));
  }, []);

  // if (loading) return <p className="contain">Loading...</p>;

  const renderNews = () => {
    return news.map((n) => {
      return (
        <div className={styles.container}>
          <Image src={n.img} />
          <p className={styles.headerText}>{n.title}</p>
          <p className={styles.bigText}>{n.description}</p>
          <NavLink to={`/news/${n.id}`}>
            <button className="grey-btn">Читать полностью</button>
          </NavLink>
        </div>
      )
    })
  }

  const renderNewsPagination = () => {
    return null
  }

  const renderArticles = () => {
    return articles.map((article) => (
      <div className={styles.article} key={article.name}>
        <NavLink to={`/articles/${article.id}`}>
          <div className={styles.title}>
            <span>{article.title}</span>
            <i>
              <FiArrowUpRight />
            </i>
          </div>
        </NavLink>
        <p className={styles.description}>{article.body}</p>
      </div>
    ))
  }

  const renderArticlesPagination = () => {
    return null
  }

  return (
    <div className="body-page">
      <div className="contain">
        <div className="flex link">
          <span>Главная</span>
          <span>Новости</span>
        </div>
        <h1 className="head1">Новости</h1>
        <div className={styles.grid}>
          {renderNews()}
        </div>
        {renderNewsPagination()}

        <h3 className={styles.head3}>Статьи</h3>
        <div className={styles.articles}>
          {renderArticles()}
        </div>
        {renderArticlesPagination()}
      </div>

      <div className={styles.bannerBlock}>
        <img src={bannerBG} alt="banner bg" />

        <div className={styles.textOnBanner}>
          <h2>Баннер с продукцией</h2>
          <p>{bigText}</p>
        </div>
      </div>

      {/*<div className="contain">*/}
        {/*<div className={styles.grid}>*/}
          {/*<div className={styles.container + " " + styles.width25}>*/}
            {/*<img src={dogImg} alt="dog" />*/}
            {/*<p className={styles.headerText}>{headText}</p>*/}
            {/*<p className={styles.bigText}>{bigText}</p>*/}
            {/*<NavLink to="/" className={styles.textBtn}>*/}
              {/*Читать полностью ...*/}
            {/*</NavLink>*/}
          {/*</div>*/}

          {/*<div className={styles.container + " " + styles.width25}>*/}
            {/*<img src={dogImg} alt="dog" />*/}
            {/*<p className={styles.headerText}>{headText}</p>*/}
            {/*<p className={styles.bigText}>{bigText}</p>*/}
            {/*<NavLink to="/" className={styles.textBtn}>*/}
              {/*Читать полностью ...*/}
            {/*</NavLink>*/}
          {/*</div>*/}

          {/*<div className={styles.container + " " + styles.width25}>*/}
            {/*<img src={dogImg} alt="dog" />*/}
            {/*<p className={styles.headerText}>{headText}</p>*/}
            {/*<p className={styles.bigText}>{bigText}</p>*/}
            {/*<NavLink to="/" className={styles.textBtn}>*/}
              {/*Читать полностью ...*/}
            {/*</NavLink>*/}
          {/*</div>*/}
        {/*</div>*/}

        {/*<div className={styles.centeredBtn}>*/}
          {/*<button className="green-btn">СМОТРЕТЬ ЕЩЕ</button>*/}
        {/*</div>*/}
      {/*</div>*/}
    </div>
  );
}
