import { useEffect, useState } from "react";
import ModalView from "../../../../components/Modal/View";
import InputMask from "react-input-mask";

import ChangePassword from "./sections/ChangePassword";
import { useSelector } from "react-redux";
import { updateUserData } from "../../../../api";
import { useCookies } from "react-cookie";
import { setBearerToken } from "../../../../utils/req";

import styles from "./PersonalData.module.css";

export default function PersonalData() {
  const [cookies, setCookie] = useCookies(["user"]);
  const [openModal, setOpenModal] = useState(false);
  const authData = useSelector((state) => state.auth?.data);
  const [formData, setFormData] = useState();

  useEffect(() => {
    setFormData({
      surname: authData?.surname || "",
      name: authData?.name || "",
      phone: authData?.phone || "",
      email: authData?.email || "",
      birthday: authData?.birthday || "",
    });
  }, []);

  const onClick = () => setOpenModal((e) => !e);

  const onSubmitForm = (e) => {
    e.preventDefault();
    let newFormData = {
      ...formData,
      phone: "+" + formData.phone.replace(/[^\d]/g, ""),
    };

    let config = setBearerToken(cookies);

    updateUserData(newFormData, config)
      .then((json) => {
        console.log("json = ", json);
      })
      .catch((err) => {
        console.log("err = ", err);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <form onSubmit={onSubmitForm}>
      <div className={styles.personalInfoBox}>
        <div className={styles.flexMargin}>
          <div className={styles.textField}>
            <label>Фамилия</label>
            <input
              name="surname"
              type="text"
              placeholder="Петров"
              value={formData?.surname}
              onChange={handleChange}
            />
          </div>
          <div className={styles.textField}>
            <label>Имя</label>
            <input
              name="name"
              type="text"
              placeholder="Петров"
              value={formData?.name}
              onChange={handleChange}
            />
          </div>
          <div className={styles.textField}>
            <label>Отчество</label>
            <input
              name="fathername"
              type="text"
              placeholder="Петров"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className={styles.flexMargin}>
          <div className={styles.textField}>
            <label>Пароль</label>
            <button className={styles.orangeBtn} onClick={onClick}>
              Изменить
            </button>
          </div>
        </div>

        <div className={styles.flexMargin}>
          <div className={styles.textField}>
            <label>Телефон</label>
            {/* backslash before number to show it */}
            <InputMask
              name="phone"
              placeholder="+7 (707) 746 47 94"
              mask="+7 (999) 999-99-99"
              maskChar="_"
              value={formData?.phone}
              onChange={handleChange}
            />
          </div>
          <div className={styles.textField}>
            <label>E-mail</label>
            <input
              name="email"
              type="text"
              placeholder="m.kasanov@gmail.com"
              value={formData?.email}
              onChange={handleChange}
            />
          </div>
          <div className={styles.textField}>
            <label>Дата рождения</label>
            <InputMask
              name="birthday"
              mask="99.99.9999"
              maskChar="_"
              value={formData?.birthday}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.flexMargin}>
          <div className={styles.textField}>
            <label>Страна проживания</label>
            <input
              name="country"
              type="text"
              placeholder="Казахстан"
              onChange={handleChange}
            />
          </div>
          <div className={styles.textField}>
            <label>Город проживания</label>
            <input
              name="city"
              type="text"
              placeholder="Алматы"
              onChange={handleChange}
            />
          </div>
          <div className={styles.textField}>
            <label>Адрес проживания </label>
            <input
              name="street"
              type="text"
              placeholder="Шевченко 165Б"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className={styles.mleft}>
          <button className={"green-btn " + styles.btn} type="submit">
            Сохранить изменения
          </button>
        </div>
      </div>

      <ModalView active={openModal} setActive={setOpenModal} hideBg>
        <ChangePassword setOpenModal={setOpenModal} />
      </ModalView>
    </form>
  );
}
