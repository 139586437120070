import { NavLink } from "react-router-dom";

import styles from "../../pages/home/home.module.css";

export default function Block({
  headerText,
  text1,
  text2,
  img,
  button = false,
  buttonLink,
}) {
  return (
    <div className={styles.aboutCompany}>
      <div className={styles.left}>
        <h2 className={styles.headerText}>{headerText}</h2>
        <p>{text1}</p>
        {text2 && <p>{text2}</p>}

        {button && (
          <NavLink
            to={buttonLink || "/"}
            className={"blueUnderline " + styles.marginBot}
          >
            Читать больше
          </NavLink>
        )}
      </div>

      <div className={styles.imgBox}>
        <img src={img} alt="dog and cat" />
      </div>
    </div>
  );
}
