import axios from "axios";
import { backUrl } from "../utils/const/url"

const url = backUrl;
const API = axios.create({
  baseURL: `${url}/api/v1`,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

const getByEndpoints = async (url, config = {}) => {
  const { data } = await API.get(url, config);
  return data;
};

const postByEndpoints = async (url, body, config = {}) => {
  const { data } = await API.post(url, body, config);
  return data;
};

const putByEndpoints = async (url, body, config = {}) => {
  const { data } = await API.put(url, body, config);
  return data;
};

const deleteByEndpoints = async (url, config = {}) => {
  const { data } = await API.delete(url, config);
  return data;
};

export const apiUrl = () => url

export const fetchBrands = () => getByEndpoints("/brands");
export const fetchBanners = () => getByEndpoints("/banners");
export const fetchManuals = () => getByEndpoints("/manuals");
export const fetchCompanyWorks = () => getByEndpoints("/company-works");
export const fetchCompanyServices = () => getByEndpoints("/company-services");

// APPLICATION
export const postRequestOnService = (formData, serviceId) =>
  postByEndpoints(`/company-services/${serviceId}/order`, formData);
export const postRequestOnFeedback = (formData) =>
  postByEndpoints("/feedback", formData);
export const postRequestOnCallback = (formData) =>
  postByEndpoints("/callback", formData);

// DISCOUNTS
export const getDiscountsByPrice = (config) =>
  getByEndpoints("/sales/check", config);
export const fetchSales = () => getByEndpoints("/sales");

// CART
export const fetchCartProducts = (config) =>
  getByEndpoints("/user/cart", config);
export const addToCart = (formData, config) =>
  postByEndpoints("/user/cart/add", formData, config);
export const deleteFromCart = (id, config) =>
  postByEndpoints("/user/cart/remove", id, config);
export const clearCart = (config) =>
  postByEndpoints("/user/cart/clear", config);

// NOTIFICATIONS
export const fetchUserNotifications = (config) =>
  getByEndpoints("/user/notifications", config);
export const fetchExactNotification = (id, config) =>
  postByEndpoints(`/user/notifications/${id}/read`, '', config);
export const fetchAllNotifications = () =>
  postByEndpoints("/user/notifications/read");

// ORDER
export const orderAsUser = (formData, config) =>
  postByEndpoints("/orders/authorized", formData, config);
export const orderAsGuest = (formData) => postByEndpoints("/orders", formData);
export const fetchPaymentTypes = () => getByEndpoints("/payment-types");
export const fetchOrderStatuses = (config) => getByEndpoints("/user/order-statuses", config);

// USER
export const signup = (formData) => postByEndpoints("/user", formData);
export const updateUserData = (formData, config) =>
  putByEndpoints("/user", formData, config);
export const getUserToken = (formData) =>
  postByEndpoints("/user/token", formData);
export const deleteUserTokens = () => deleteByEndpoints("/user/tokens/revoke");
export const getUser = (config) => getByEndpoints("/user", config);
export const sendResetPasswordLink = (email, config) =>
  postByEndpoints("/user/password/reset-link/send", email, config);
export const getOrders = (config) => getByEndpoints("/user/orders", config);
export const fetchUserReviews = (config) => getByEndpoints("/user/reviews", config);
export const fetchUserBonuses = (config) => getByEndpoints("/user/get-user-bonuses", config);
export const applyBonusesOrPromo = (data, config) => postByEndpoints("/user/apply-bonuses-or-promo", data, config);

// USER PET
export const fetchPets = (config) => getByEndpoints("/user/pets", config);
export const postPet = (formData) => postByEndpoints("/user/pets", formData);
export const updatePet = (petId, formData) =>
  putByEndpoints(`/user/pets/${petId}`, formData);
export const deletePet = (petId, formData) =>
  deleteByEndpoints(`/user/pets/${petId}`, formData);

// REVIEWS
export const fetchReviews = (productId) =>
  getByEndpoints(`/products/${productId}/reviews`);
export const postReview = (productId, body, config) =>
  postByEndpoints(`/products/${productId}/reviews`, body, config);

// CONTACT
export const fetchFilials = () => getByEndpoints("/filials");
export const fetchCities = () => getByEndpoints("/countries");

// ARTICLES
export const fetchArticles = () => getByEndpoints("/articles");
export const fetchExactArticle = (id) => getByEndpoints(`/articles/${id}`);

// NEWS
export const fetchNews = () => getByEndpoints("/news");
export const fetchExactNews = (id) => getByEndpoints(`/news/${id}`);

// ITEMS
export const searchItem = (query) => getByEndpoints(`/search?query=${query}`);
export const fetchExactItem = (id) => getByEndpoints(`/products/${id}`);

// CATEGORIES
export const fetchCategories = () => getByEndpoints("/categories");
export const fetchItemsByCategory = (config = {}) =>
  getByEndpoints(`/products`, config);

// SERVICES
export const fetchServices = () => getByEndpoints("/company-services");
export const orderService = (id, data) => postByEndpoints(`/company-services/${id}/order`, data);

//PROMOTIONS
export const subscribe = (data) => postByEndpoints("/subscribe", data)
export const fetchPromotions = () => getByEndpoints("/promotions")

// PAGES
export const getAboutPage = () => getByEndpoints("/about-company")
export const getDeliveryPage = () => getByEndpoints("/delivery")