import styles from "./s.module.css";

const ModalView = ({
  active,
  setActive,
  centered = true,
  number = "0",
  hideBg = false,
  children,
}) => {
  return (
    <div
      className={active ? styles.modal + " " + styles.active : styles.modal}
      onClick={() => setActive(false)}
      style={{
        alignItems: centered ? "center" : "flex-end",
        justifyContent: centered ? "center" : "flex-end",
      }}
    >
      <div
        className={
          active
            ? styles.modalContent + " " + styles.active
            : styles.modalContent
        }
        style={{
          marginRight: number,
          marginBottom: number,
          backgroundColor: hideBg ? "transparent" : "white",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalView;
