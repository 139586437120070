import * as api from "../../api";

import logo from "./../../assets/icons/white_logo.svg";

import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import vk from "./../../assets/icons/vk.svg";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import { openCallback, closeCallback } from '../../actions/modalAction'


import styles from "./footer.module.css";

const company = [
  {
    name: "О компании",
    href: "/about",
  },
  {
    name: "Доставка",
    href: "/delivery",
  },
  {
    name: "Контакты",
    href: "/contact",
  },
  {
    name: "Наши работы",
    href: "/works",
  },
  {
    name: "Новости",
    href: "/news",
  },
];

const customers = [
  {
    name: "Акции",
    href: "/promotions",
  },
  {
    name: "Справочники",
    href: "/references",
  },
  {
    name: "Новинки",
    href: "/novelty",
  },
  {
    name: "Наши услуги",
    href: "/services",
  },
];

export default function Footer({ categories }) {
  const dispatch = useDispatch()
  const services = useSelector((state) => state.services);
  const history = useHistory();

  return (
    <footer className={styles.footer}>
      <div className="contain">
        <div className={styles.grid}>
          <div className={styles.column1}>
            <strong>Каталог товаров</strong>
            {categories?.map((category) => (
              <p
                key={category.name}
                onClick={() => {
                  history.push(`/catalog?category_id=${category.id}`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={styles.link}
              >
                {category.name}
              </p>
            ))}
          </div>
          <div className={styles.column2}>
            <strong>Покупателям</strong>
            {customers.map((item) => (
              <p
                key={item.name}
                onClick={() => history.push(item.href)}
                className={styles.link}
              >
                {item.name}
              </p>
            ))}
          </div>
          <div className={styles.column3}>
            <strong>Услуги</strong>
            {services.map((item) => (
              <p
                key={item.name}
                onClick={() => history.push('/services#')}
                className={styles.link}
              >
                {item.name}
              </p>
            ))}
          </div>
          <div className={styles.column4}>
            <strong>Компания</strong>
            {company.map((item) => (
              <p
                key={item.name}
                onClick={() => history.push(item.href)}
                className={styles.link}
              >
                {item.name}
              </p>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.secondRow}>
        <div className="contain">
          <div className={styles.flex}>
            <img src={logo} alt="logo" className={styles.logoImg} />
            <div className={styles.right}>
              <div className={styles.flexReverse}>
                <strong>+7 (903) 154-70-11</strong>
                <span onClick={() => dispatch(openCallback())} className="pointer">
                  Заказать звонок
                </span>
                <a href="mailto:order@zhivoimir.kz">
                  <span className="pointer">order@zhivoimir.kz</span>
                </a>
              </div>
              <div className={styles.socials}>
                <AiOutlineInstagram />
                <img src={vk} alt="vkontakte" />
                <FaFacebookF />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.thirdRow}>
        <div className="contain">
          <div className={styles.flexReverse}>
            <span>© Zhivoi mir 2021. Все права защищены</span>
            <a href={`${api.apiUrl()}/storage/terms_of_use.pdf`} target="_blank" rel="noreferrer">
              <span>Пользовательское соглашение</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
