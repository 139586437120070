export const GET_USER_DATA = "GET_USER_DATA";
export const USER_DATA_LOADING = "USER_DATA_LOADING";
export const USER_DATA_ERROR = "USER_DATA_ERROR";
export const LOGOUT = "LOGOUT";

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_DATA:
      return {
        error: false,
        loading: false,
        message: "",
        data: action.payload.data,
        config: action.payload.config,
      };
    case USER_DATA_ERROR:
      return {
        data: null,
        loading: false,
        error: true,
        message: action.payload,
      };
    case LOGOUT:
      return {
        loading: false,
        error: false,
        message: "",
        data: null,
      };
    default:
      return state;
  }
};
