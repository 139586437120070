import { NavLink, useHistory } from "react-router-dom";

import feed from "../../assets/icons/feed.svg";
import dog_cat from "../../assets/images/cat-dog.jpg";
import aquarium from "../../assets/images/aquarium.jpg";
import brand from "../../assets/icons/brand.svg";
import perk from "../../assets/icons/perks.svg";
import bigDog from "../../assets/images/home/bigDog.svg";

import CarouselTopArrows from "../../components/CarouselTopArrows/CarouselTopArrows";
import FeedCard from "../../components/Cards/FeedCard";
import AnimalCard from "../../components/Cards/AnimalCard";
import Carousel from "../../components/Carousel";
import * as api from "../../api";
import { useState, useEffect } from "react";

import Block from "../../components/Block/Block";
import {
  ADVANTAGES,
  ANIMAL_CATALOG,
  ANIMAL_TEXT,
  SERVICE_TEXT,
} from "../../utils/const/home";

import styles from "./home.module.css";
import useWindowSize from "../../hooks/useWindowSize";
import MobileCarousel from "../../components/Carousel/mobileCarousel";
import Image from "../../utils/Image";

const head1 = "Товары для собак";
const text1 =
  "Все, что необходимо вашему любимцу вы сможете найти  у нас. Постоянное обновление товара и лучшие скидки.";

const text2 =
  "Сухой корм для взрослых собак средних пород крупных пород с ягненком и рисом";

const text3 =
  "Сеть зоомагазинов «Живой Мир» берет свое начало с 2000 г., когда большая любовь к аквариумным рыбкам и декоративным птичкам переросла в открытие зоомагазина в г. Талгар Алматинской области. В 2005 г. начался активный рост и развитие компании «Живой Мир».";
const text4 =
  "Основные приоритеты и направления были: аквариумные рыбки и декоративная птица. В дальнейшем, с приобретением опыта и развития компании, открылись филиалы и в других городах Казахстана: Алматы, Талгар, Актау, Уральск и Павлодар";

export default function Home({ categories = [] }) {
  const [bannerIndex, setBannerIndex] = useState(0);
  const [banners, setBanners] = useState([]);
  const [brands, setBrands] = useState([]);
  const [services, setServices] = useState([]);
  const [allHitProducts, setAllHitProducts] = useState([]);
  const [allNoveltyProducts, setAllNoveltyProducts] = useState([]);
  const width = useWindowSize();
  const history = useHistory();

  useEffect(() => {
    api.fetchBrands().then((val) => {
      if (val.success) setBrands(val.data);
    });

    api.fetchBanners().then((val) => {
      if (val.success) setBanners(val.data);
    });

    api
      .fetchItemsByCategory({ params: { novelty: 0, hit: 1, discounted: 0 } })
      .then((json) => {
        if (json.success) setAllHitProducts(json.data.products);
      });

    api
      .fetchItemsByCategory({ params: { novelty: 1, hit: 0, discounted: 0 } })
      .then((json) => {
        if (json.success) setAllNoveltyProducts(json.data.products);
      });

    api.fetchCompanyServices().then((val) => {
      if (val.success) setServices(val.data);
    });
  }, []);

  function renderCategories() {
    return categories.map((card, i) => {
      let subcategories = card.children
      return (
        <AnimalCard
          key={i}
          id={"/catalog?category_id=" + card.id}
          categories={subcategories}
          title={card.name}
          img={card.thumbnail}
          alt={card.name}
          animalImg={true}
        />
      )
    })
  }
  return (
    <div className={styles.homePage}>
      <div className={styles.carousel + " contain"}>
        <Carousel
          slidesToShow={1}
          length={banners.length}
          dots
          alwaysOneSlide
          onBannerChange={(i) => setBannerIndex(i)}
        >
          {banners.map((banner, i) => (
            <div key={i} className={styles.slide}>
              <div className={styles.leftText}>
                <h2>{banner.title}</h2>
                <p>{banner.description}</p>
                <button
                  className="green-btn"
                  onClick={() => {
                    let url = banner.button_url;
                    history.push(url);
                  }}
                >
                  {banner.button_title}
                </button>
              </div>
              <div className={styles.imgBox}>
                <Image src={banner.background_image} alt={banner.title} />
              </div>
              <Image
                src={banner.background_image}
                alt={banner.title}
                className={styles.mobileImg}
                style={{ display: width > 700 ? "none" : "block" }}
              />

              <div
                className={styles.circle}
                style={{
                  backgroundColor: banner.background_color,
                  display: bannerIndex === i ? "block" : "none",
                }}
              ></div>
              <div className={styles.blurCircle}></div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className="contain">
        <div className={styles.animalCards}>
          <div className="flex-wrap">
            {renderCategories()}
            {ANIMAL_CATALOG.map((card, i) => (
              <AnimalCard
                key={i}
                categories={ANIMAL_TEXT}
                id={card.id}
                title={card.name}
                img={card.img}
                alt={card.name}
                animalImg={card.animal}
              />
            ))}
          </div>
        </div>

        <div>
          <div className={styles.headerBox}>
            <h2 className={styles.headerText}>Хиты продаж</h2>
            <NavLink
              to="/catalog?hit=1"
              className={"blueUnderline " + styles.underline}
            >
              Все хиты продаж
            </NavLink>
          </div>

          <div className="negative-contain">
            {width > 700 ? (
              <Carousel
                slidesToShow={4}
                rows={
                  1
                  // allHitProducts.length > 12
                  //   ? 3
                  //   : allHitProducts.length > 8
                  //   ? 2
                  //   : 1
                }
                slidesToScroll={
                  1
                  // allHitProducts.length > 12
                  //   ? 3
                  //   : allHitProducts.length > 8
                  //   ? 2
                  //   : 1
                }
                arrows
                arrowTop
                infinite={false}
              >
                {allHitProducts.map((product, i) => {
                  return (
                    <FeedCard
                      key={product.id}
                      id={product.id}
                      title={product.name}
                      itemType={product.category}
                      img={product.thumbnail}
                      text={product.description}
                      prevPrice={i % 2 === 0 ? "850" : undefined}
                      price={product.price}
                      // bannerText={i % 2 === 0 ? "Акция" : undefined}
                      // bannerColor="orange"
                      wrapNumber={1}
                      moveLeft
                    />
                  );
                })}
              </Carousel>
            ) : (
              <MobileCarousel slidesToShow={2}>
                {allHitProducts.map((product, i) => {
                  return (
                    <FeedCard
                      key={product.id}
                      id={product.id}
                      title={product.name}
                      itemType={product.category}
                      img={product.thumbnail}
                      text={product.description}
                      prevPrice={i % 2 === 0 ? "850" : undefined}
                      price={product.price}
                      // bannerText={i % 2 === 0 ? "Акция" : undefined}
                      // bannerColor="orange"
                      wrapNumber={1}
                      moveLeft
                    />
                  );
                })}
              </MobileCarousel>
            )}
          </div>

          <div className="mobile-button">
            <button className="green-btn">Все хиты продаж</button>
          </div>
        </div>

        <Block
          headerText="О компании"
          text1={text3}
          text2={text4}
          img={dog_cat}
          button
          buttonLink="/about"
        />

        <CarouselTopArrows
          headerText="Новые поступления"
          linkText="Все новинки"
          img={feed}
          moveLeft
          link="/catalog/?novelty=1"
          arrayItems={allNoveltyProducts}
        />

        <div className={styles.services}>
          <h2 className={styles.head2}>Наши услуги</h2>

          <div className={styles.aquariumBox}>
            <img src={aquarium} alt="aquarium" />
          </div>
          <div className={styles.right}>
            <h2 className={styles.head2}>Наши услуги</h2>
            {services.map((service) => (
              <p key={service.name}>{service.name}</p>
            ))}
          </div>
        </div>

        {brands?.length > 0 && (
          <div className={styles.brands}>
            <div className={styles.headerBox}>
              <h2 className={styles.headerText}>Популярные бренды</h2>
            </div>
            <Carousel
              slidesToShow={4}
              breakpoint1500={5}
              breakpoint1050={4}
              arrows
              sideArrows
              infinite={false}
            >
              {brands.map((brand) => (
                <span key={brand.name}>
                  <div className={styles.card}>
                    <Image src={brand.thumbnail} alt="brand.name" />
                  </div>
                </span>
              ))}
            </Carousel>
          </div>
        )}

        <div className={styles.perks}>
          <h2 className={styles.headerText}>Почему выбирают нас</h2>
          <div className={styles.flexWrap}>
            <div className={styles.perksCard}>
              <div className={styles.numberInBox}>
                <img src={perk} alt="perk" />
                <div className={styles.number}>25</div>
              </div>
              <p>Работаем на рынке Казахстана 25 лет</p>
            </div>
            {ADVANTAGES.map((item, i) => (
              <div key={i} className={styles.perksCard}>
                <div className={styles.numberInBox}>
                  <img src={item.img} alt="perk" />
                </div>
                <p>{item.name}</p>
              </div>
            ))}
          </div>

          <div className={styles.mobilePerks}>
            <div className="negative-contain">
              <Carousel slidesToShow={1}>
                <div className={styles.perksCard}>
                  <div className={styles.numberInBox}>
                    <img src={perk} alt="perk" />
                    <div className={styles.number}>25</div>
                  </div>
                  <p>Работаем на рынке Казахстана 25 лет</p>
                </div>
                {ADVANTAGES.map((item, i) => (
                  <div key={i} className={styles.perksCard}>
                    <div className={styles.numberInBox}>
                      <img src={item.img} alt="perk" />
                    </div>
                    <p>{item.name}</p>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
