import React, { useState, useRef } from "react";
import Slider from "react-slick";

import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import styles from "./Carousel.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SamplePrevArrow(props) {
  const { onClick, arrowTop, onPrev, sideArrows, firstSlide } = props;

  return (
    <React.Fragment>
      <div
        className={
          sideArrows
            ? styles.sidePrevArrow + " " + styles.btn
            : arrowTop
            ? styles.prevBtn2 + " " + styles.btn
            : styles.prevBtn1 + " " + styles.btn
        }
        style={{
          color: firstSlide ? "#ccc" : "black",
        }}
        onClick={() => {
          if (onClick) onClick();
          if (onPrev) onPrev();
        }}
      >
        <MdKeyboardArrowLeft />
      </div>
      {!sideArrows && <div className={styles.line}></div>}
    </React.Fragment>
  );
}

function SampleNextArrow(props) {
  const { onClick, arrowTop, onNext, sideArrows, lastSlide } = props;

  return (
    <div
      className={
        sideArrows
          ? styles.sideNextArrow + " " + styles.btn
          : arrowTop
          ? styles.nextBtn2 + " " + styles.btn
          : styles.nextBtn1 + " " + styles.btn
      }
      style={{
        color: lastSlide ? "#ccc" : "black",
      }}
      onClick={() => {
        if (onClick) onClick();
        if (onNext) onNext();
      }}
    >
      <MdKeyboardArrowRight />
    </div>
  );
}

const Carousel = ({
  slidesToShow,
  dots = false,
  length,
  children,
  alwaysOneSlide = false,
  arrows = false,
  sideArrows = false,
  centerMode = false,
  breakpoint1050 = 3,
  breakpoint1500,
  rows = 1,
  slidesToScroll = 1,
  arrowTop = false,
  infinite = true,
  onBannerChange,
  slidesToShowInMobile = 1,
}) => {
  const [index, setIndex] = useState(0);
  const [firstSlide, setFirstSlide] = useState(true);
  const [lastSlide, setLastSlide] = useState(false);
  const sliderRef = useRef(null);

  const onNext = () =>
    setIndex((i) => {
      if (length - 1 === i) {
        if (onBannerChange) onBannerChange(0);
        return 0;
      }

      if (onBannerChange) onBannerChange(i + 1);
      return i + 1;
    });

  const onPrev = () =>
    setIndex((i) => {
      if (i === 0) {
        if (onBannerChange) onBannerChange(length - 1);
        return length - 1;
      }
      if (onBannerChange) onBannerChange(i - 1);

      return i - 1;
    });

  const responsiveSlides = alwaysOneSlide
    ? []
    : [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: breakpoint1500 ?? slidesToShow,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: breakpoint1050,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 700,
          settings: {
            rows: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "25%",
          },
        },
      ];

  const settings = {
    arrows: arrows,
    dots: false,
    infinite: infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    nextArrow: (
      <SampleNextArrow
        arrowTop={arrowTop}
        onNext={onNext}
        sideArrows={sideArrows}
        lastSlide={lastSlide}
      />
    ),
    prevArrow: (
      <SamplePrevArrow
        arrowTop={arrowTop}
        onPrev={onPrev}
        sideArrows={sideArrows}
        firstSlide={firstSlide}
      />
    ),
    beforeChange: (_, next) => {
      if (onBannerChange) onBannerChange(next);
      if (next === 0 && !firstSlide) setFirstSlide(true);
      else if (firstSlide) setFirstSlide(false);
      if (next === slidesToShow && !lastSlide) setLastSlide(true);
      else if (lastSlide) setLastSlide(false);
    },
    afterChange: (i) => {
      setIndex(i);
    },
    responsive: responsiveSlides,
    centerMode: centerMode,
    centerPadding: "3rem",
    rows: rows,
  };

  return (
    <div className={styles.slider}>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>

      {dots && (
        <div className={styles.dotsBox}>
          {[...Array(length)].map((_, i) => (
            <div
              key={i}
              onClick={() => {
                if (onBannerChange) onBannerChange(i);
                setIndex(i);
                sliderRef.current.slickGoTo(i);
              }}
              className={
                index === i ? `${styles.dot} ${styles.active}` : styles.dot
              }
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel;
