import * as api from "../api";
import {FETCH_SALES} from "../reducers/salesReducer";

export const fetchSales = () => async (dispatch) => {
  try {
    const data = await api.fetchSales();
    if (data.success)
      dispatch({ type: FETCH_SALES, payload: data.data });
  } catch (error) {
    dispatch({ type: FETCH_SALES });
  }
}