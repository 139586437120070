import styles from "./Memo.module.css";
import { DATA, DATA2, DATA3, DATA4, DATA5 } from "./data";

const arr = [
  "Условия доставки",
  "Оплата товара",
  "Возврат товара и денежных средств",
  "Конфиденциальность и защита информации",
  "Гарантия",
];

export default function Memo() {
  const renderData = (param) => {
    switch (param) {
      case 0:
        return DATA;
      case 1:
        return DATA2;
      case 2:
        return DATA3;
      case 3:
        return DATA4;
      case 4:
        return DATA5;
      default:
        return [];
    }
  };

  return (
    <div className={styles.box}>
      <h2 className={styles.head2}>Памятка клиента</h2>
      <div className={styles.links}>
        {arr.map((link, i) => (
          <div
            key={link}
            className={styles.link}
            onClick={(e) => {
              e.preventDefault();
              let target = document.getElementById(link);
              window.scrollTo({
                top: target.offsetTop - 100,
                behavior: "smooth",
              });
            }}
          >
            <span className={styles.num}>{i + 1}.</span>
            <span className={styles.underline}>{link}</span>
          </div>
        ))}
      </div>
      {arr.map((link, i) => (
        <div key={link + i}>
          <p className={styles.head}>
            {i + 1}. {link}
          </p>
          <div>
            {renderData(i).map((data) => (
              <p id={link} key={data.substring(0, 5)}>
                {data}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
