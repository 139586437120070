import styles from "./OrderDetails.module.css";
import Image from "../../../../utils/Image";

export default function OrderDetails({ data }) {
  const renderItem = () => {
    return data?.order_items?.map((item) => {
      return (
        <div className={styles.feedCard}>
          <Image src={item?.thumbnail} alt="feed" />
          <div>
            <p className={styles.w600}>{item?.name}</p>
            <p className={styles.w400}>{item?.description}</p>
            <p className={styles.w400}>Количество: {item?.unit_quantity}</p>
            <p className={styles.price}>{item?.price} ₸</p>
          </div>
        </div>
      )
    })
  }

  return (
    <div className={styles.card}>
      <div>
        <strong className={styles.head3}>Заказ №1273</strong>
        <span>Доставлено 16 июня</span>
        <p className={styles.greenText}>{data?.status}</p>
        <div className={styles.row}>
          <strong className={styles.tab}>Продавец:</strong>
          <span>Живой мир</span>
        </div>
        <div className={styles.row}>
          <strong className={styles.tab}>Способ доставки:</strong>
          <span>{data?.delivery_type?.name}</span>
        </div>
      </div>
      {renderItem()}
    </div>
  );
}
