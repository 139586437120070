import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ isAuthenticated, exact = true, path, children }) {
  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
